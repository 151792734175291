import React from "react";
import { Box, Typography, List, ListItem, ListItemText } from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

const MissionList = () => {
  const missions = [
    "Collecter les données biométriques et biographiques nécessaires à l’identification des personnes ainsi que la production des titres sécurisés ;",
    "Créer et mettre à jour le système de registre national des populations ;",
    "Assurer en concertation avec les administrations concernées, l’enregistrement et la mise à jour des informations d’identification des citoyens et des étrangers résidents ou de passage au Tchad ;",
    "Acquérir et mettre à la disposition des administrations intéressées, les procédures, les matériels et équipements nécessaires à l’accès aux données du registre national des populations ;",
    "Personnaliser et produire les titres sécurisés par la mise en œuvre d’un système fiable et sécurisé ;",
    "Mettre en place l’infrastructure cryptographique permettant de produire des titres sécurisés et de garantir l’intégrité des données, leur confidentialité et leur authenticité ;",
    "Assurer ou faire assurer, le développement, la maintenance et l’évolution des systèmes et des réseaux informatiques permettant la gestion du registre des populations et de la production des titres sécurisés ;",
    "Définir les normes techniques et les dispositifs correspondants, en contrôler et en évaluer l’application à leur évolution et veiller à leur interopérabilité ;",
    "Promouvoir les technologies, les systèmes et les savoir-faire nationaux en matière des titres sécurisés ;",
    "Concevoir et mettre en œuvre un programme d’information et de sensibilisation des populations sur la législation et la réglementation régissant l’Etat civil.",
  ];

  return (
    <Box
    maxWidth="lg"
      sx={{
        backgroundColor: "#f9f9f9",
        padding: 4,
        borderRadius: 2,
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    
        margin: "auto",
      }}
    >
      <Typography
        variant="h5"
        sx={{ fontWeight: "bold", color: "#1D3557", marginBottom: 2 }}
      >
        Mission de l’ANATS
      </Typography>
      <Typography
        variant="body1"
        sx={{ color: "#333", marginBottom: 2, textAlign: "justify" }}
      >
        L’ANATS a pour mission de répondre aux besoins des administrations de l’Etat en
        matière de titres sécurisés. Ces titres sont des documents délivrés par l’Etat
        faisant l’objet d’une procédure d’édition et de contrôle sécurisé.
      </Typography>
      <List>
        {missions.map((mission, index) => (
          <ListItem key={index} sx={{ alignItems: "flex-start" }}>
            <FiberManualRecordIcon
              sx={{ color: "#1D3557", fontSize: 12, marginRight: 1, marginTop: 1 }}
            />
            <ListItemText primary={mission} sx={{ textAlign: "justify" }} />
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default MissionList;
