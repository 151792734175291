import React from 'react';
import Slider from 'react-slick';
import { Card, CardContent, Typography, Box } from '@mui/material';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import SectionTitle from '../SectionTitle';

const partners = [
    { logo: '/images/partners/banque_mondiale.jpg', name: 'Banque Mondiale', url: 'https://www.worldbank.org' },
    { logo: '/images/partners/hcr.png', name: 'HCR', url: 'https://www.unhcr.org' },
    { logo: '/images/partners/unicef.png', name: 'UNICEF', url: 'https://www.unicef.org' },
    { logo: '/images/partners/oim.jpg', name: 'OIM', url: 'https://www.iom.int' },
    { logo: '/images/partners/tecnidev.jpg', name: 'Technidev', url: '#' },
    { logo: '/images/partners/pag2.png', name: 'PAG2', url: '#' },
    { logo: '/images/partners/caritas.png', name: 'Caritas', url: 'https://www.caritas.org' },
    { logo: '/images/partners/afd.jpg', name: 'AFD', url: 'https://www.afd.fr' },
    { logo: '/images/partners/intersos.jpg', name: 'Intersos', url: 'https://www.intersos.org' },
    { logo: '/images/partners/airtel.png', name: 'Airtel', url: 'https://www.airtel.com' },
    { logo: '/images/partners/moov.jpg', name: 'Moov', url: 'https://www.moov.com' }
];

const Partners = () => {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    };

    return (
        <div style={{ padding: '40px', backgroundColor: '#f4f6f8' }}>
            <Typography 
                variant="h4" 
                align="center" 
                gutterBottom 
                sx={{ fontWeight: 'bold', color: '#333' }}
            >
             <SectionTitle text= "Nos Partenaires"/>
            </Typography>
            <Slider {...settings}>
                {partners.map((partner, index) => (
                    <div key={index}>
                      
                        <Card 
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                flexDirection: 'column',
                                padding: '0px',
                                borderRadius: '0px',
                                boxShadow: 3,
                                transition: 'transform 0.3s ease-in-out',
                                '&:hover': { transform: 'scale(1.05)', cursor: 'pointer' }
                            }}
                            onClick={() => window.open(partner.url, '_blank')}
                        >
                            <Box sx={{ width: 100, height: 100, marginBottom: 5 }}>
                                <img src={partner.logo} alt={partner.name} style={{ width: '150%', height: '150%', objectFit: 'contain' }} />
                            </Box>
                            {/* <CardContent>
                                <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#555', textAlign: 'center' }}>
                                    {partner.name}
                                </Typography>
                            </CardContent> */}
                        </Card>
                    </div>
                ))}
            </Slider>
        </div>
    );
};

export default Partners;