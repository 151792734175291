import { useEffect } from "react";

const FacebookPageEmbed = () => {
  useEffect(() => {
     const script = document.createElement("script");
    script.async = true;
    script.defer = true;
    script.crossOrigin = "anonymous";
    script.src = "https://connect.facebook.net/en_GB/sdk.js#xfbml=1&version=v22.0";
    script.onload = () => {
      if (window.FB) {
        window.FB.XFBML.parse();
      }
    };
    document.body.appendChild(script);
  }, []);

  return (
    <div>
      <div
        className="fb-page"
        data-href="https://www.facebook.com/anatstchad"
        data-tabs="timeline"
        data-width="550"
        data-height="900"
        data-small-header="false"
        data-adapt-container-width="true"
        data-hide-cover="false"
        data-show-facepile="true"
      ></div>
    </div>
  );
};

export default FacebookPageEmbed;
