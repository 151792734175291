import React, { useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Pagination,
  Box,
  CardMedia,
  TextField,
  InputAdornment,
  Button,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import SectionTitle from "../SectionTitle";
import { newsData } from "../../data/newsData";



const News = () => {
  const [page, setPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const itemsPerPage = 6;

  const filteredNews = newsData.filter((news) =>
    news.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const currentNews = filteredNews.slice((page - 1) * itemsPerPage, page * itemsPerPage);

  return (
    <Box sx={{ padding: "20px", maxWidth: "lg", margin: "0 auto", backgroundColor: "#f8f9fa" }}>
      <SectionTitle text="Nos Actualités" />

       <Box sx={{ display: "flex", justifyContent: "center", mb: 4 }}>
        <TextField
          variant="outlined"
          placeholder="Rechercher une actualité..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          sx={{ width: "100%", maxWidth: "lg", backgroundColor: "#fff", borderRadius: 2 }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon color="primary" />
              </InputAdornment>
            ),
          }}
        />
      </Box>

       <Grid container spacing={4}>
        {currentNews.map((news) => (
          <Grid item xs={12} sm={6} md={4} key={news.id}>
            <motion.div whileHover={{ scale: 1.05 }} transition={{ type: "spring", stiffness: 300 }}>
              <Card
                sx={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  borderRadius: 2,
                  boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
                  transition: "box-shadow 0.3s ease-in-out",
                  "&:hover": { boxShadow: "0 8px 20px rgba(0, 0, 0, 0.2)" },
                }}
              >
                {/* Image */}
                <CardMedia component="img" height="200" image={news.images[0]} alt={news.title} />

                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography variant="h6" sx={{ fontWeight: "bold", color: "#1D3557", mb: 1 }}>
                    {news.title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
                    <strong>Date:</strong> {news.date} | <strong>Lieu:</strong> {news.place}
                  </Typography>
                
                  <Button component={Link} to={`/Actualites/${news.id}`} sx={{ mt: 1 }} color="primary">
                    Voir Plus
                  </Button>
                </CardContent>
              </Card>
            </motion.div>
          </Grid>
        ))}
      </Grid>

       <Box sx={{ display: "flex", justifyContent: "center", marginTop: 4 }}>
        <Pagination
          count={Math.ceil(filteredNews.length / itemsPerPage)}
          page={page}
          onChange={(event, value) => setPage(value)}
          color="primary"
        />
      </Box>
    </Box>
  );
};

export default News;
