import React from "react";
import { Box, Typography, Container, Grid, Link, IconButton, Divider } from "@mui/material";
import { Facebook, Twitter, Mail } from "@mui/icons-material";

const Footer = () => {
  const links = [
    { path: "/", 
      label: "Accueil" 
    },
    {
       path: "/Presentation", 
       label: "Presentation" },
    { 
      path: "/Actualites", 
      label: "Actualités" },
    { 
      path: "/Contact", 
      label: "Contact" 
    },
    {
      path:"/Reclamation",
      label:"Reclamation"
    },
  ];

  return (
    <Box 
      sx={{ 
        bgcolor: "#0D1B40", 
        color: "white", 
        py: 4, 
        boxShadow: "0 -4px 10px rgba(0,0,0,0.2)", 
        position: "relative"
      }}
    >
      <Container>
        <Grid container spacing={4} justifyContent="space-between">
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="h6" fontWeight="bold">
              République du Tchad
            </Typography>
            <Typography variant="body2" sx={{ opacity: 0.8, mt: 1 }}>
              Unité - Travail - Progrès
            </Typography>
            <Typography variant="body2" sx={{ opacity: 0.8 }}>
              Agence Nationale des Titres Sécurisés (ANATS)
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="h6" fontWeight="bold">
              Liens Rapides
            </Typography>
            <Box mt={1}>
              {links.map((link, index) => (
                <Link 
                  key={index} 
                  href={link.path} 
                  color="inherit" 
                  sx={{
                    display: "block", 
                    mb: 1, 
                    transition: "color 0.3s",
                    "&:hover": { color: "#FFD700" }
                  }}
                >
                  {link.label}
                </Link>
              ))}
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="h6" fontWeight="bold">
              Contactez-nous
            </Typography>
            <Box mt={1}>
              <Typography   fontWeight="bold" variant="body2" sx={{ opacity: 0.8 }}>
                Adresse : Avenue du General Charfadine Ahmat, N’djamena Tchad
              </Typography>
              <Typography fontWeight="bold" variant="body2" sx={{ opacity: 0.8 }}>
                Téléphone : +235 93 51 79 79
              </Typography>
              <Typography fontWeight="bold" variant="body2" sx={{ opacity: 0.8 }}>
                Email : contact@anats.td
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="h6" fontWeight="bold">
              Suivez-nous
            </Typography>
            <Box mt={1}>
              {[{ icon: <Facebook />, link: "https://facebook.com" }, { icon: <Twitter />, link: "https://twitter.com" }, { icon: <Mail />, link: "mailto:contact@anats.td" }]
                .map((item, index) => (
                  <IconButton
                    key={index}
                    href={item.link}
                    color="inherit"
                    sx={{
                      textDecoration:"none",
                      bgcolor: "rgba(255, 0, 0, 0.1)",
                      mr: 1,
                      transition: "transform 0.3s, background 0.3s",
                      "&:hover": { transform: "scale(1.1)",
                         bgcolor: "#FFD700" }
                    }}
                  >
                    {item.icon}
                  </IconButton>
              ))}
            </Box>
          </Grid>
        </Grid>

        <Divider sx={{ bgcolor: "rgba(255,255,255,0.2)", my: 3 }} />

        <Box sx={{ textAlign: "center" }}>
          <Typography variant="body2" sx={{ opacity: 0.7 }}>
            &copy; {new Date().getFullYear()} Copyright RDI Tous droits réservés 
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;