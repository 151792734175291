import React from "react";
import { Typography, Box } from "@mui/material";
import { motion } from "framer-motion";

const SectionTitle = ({ text, color = "#031B4D" }) => {
  return (
    <Box sx={{ textAlign: "center", marginBottom: 4, position: "relative" }}>
       <motion.div
        initial={{ opacity: 0, y: -10 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <Typography
          variant="h4"
          sx={{
            fontWeight: "bold",
            fontSize: { xs: "1.5rem", md: "2rem" },
            color: color,
            textTransform: "uppercase",
            letterSpacing: "1px",
            position: "relative",
            display: "inline-block",
            paddingBottom: "8px",
          }}
        >
          {text}
        </Typography>
      </motion.div>

       <motion.div
        initial={{ scaleX: 0 }}
        animate={{ scaleX: 1 }}
        transition={{ duration: 0.5, delay: 0.2 }}
        style={{
          width: "80px",
          height: "4px",
          backgroundColor: color,
          margin: "5px auto 0",
          borderRadius: "2px",
        }}
      />
    </Box>
  );
};

export default SectionTitle;
