import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import { Typography, Card, CardContent, Box, Grid } from "@mui/material";
import React, { useRef } from "react";

 

const centers = [
 
 
 

  { name: "CAU de 1er Arrondissement	Farcha", contact: "+235 65 99 99 70", address: "Farcha", coordinates: [12.127031, 14.993681], chef: "Tchaea Ganga" },
  { name: "CAU de	2ème Arrondissement	Klémat", contact: "+235 65 99 99 70", address: "Klémat", coordinates: [12.131733, 15.047345], chef: "Kerim Saleh" },
  { name: "CAU de 5ème Arrondissement	Gouvernorat	", contact: "+235 987 654 321", address: "5ème Arrondissement", coordinates: [12.107709, 15.082863], chef: "Moudjahid" },
  { name: "CAU de 6ème Arrondissement	Moursal ", contact: "+235 987 654 321", address: "Moursal", coordinates: [212.101050, 15.071000] },

  { name: "	CAU de 7ème Arrondissement	Chagoua ", contact: "+235 65 99 99 70", address: "Chagoua", coordinates: [12.092378, 15.096770], chef: "Tchaea Ganga" },
  { name: "	CAU de	8ème Arrondissement	Ndjari	", contact: "+235 65 99 99 70", address: "Ndjari", coordinates: [12.129033, 15.098938 ], chef: "Kerim Saleh" },
  { name: "	CAU de 10ème Arrondissement	Goudji  ", contact: "+235 65 99 99 70", address: "Goudji", coordinates: [12.16738, 15.04615], chef: "Tchaea Ganga" },
  { name: "	Centre des Transports	Djambalgato	", contact: "+235 65 99 99 70", address: "Djambalgato", coordinates: [12.12112, 15.01988], chef: "Kerim Saleh" },

 
  { name: "CAU d’Amdjarass 	Ennedi Est", address: "Ennedi Est", coordinates: [16.070620, 22.839525], chef: "Achata Oki" },
  { name: "CAU de Fada	Ennedi Ouest",   address: "Ennedi Ouest", coordinates: [17.187130, 	21.574459], chef: "Brahim Mht Saleh" },
  { name: "CAU d’Abéché 	Ouaddaï", address: "Abéché", coordinates: [13.82937, 20.82918], chef: "Tcheri" },
  
  
  { name: "CAU de Mongo	Guéra", contact: "+235 68 88 88 08", address: "Guéra", coordinates: [12.181581, 18.685311], chef: "Issa Kali" },
  { name: "CAU de Mao 	Kanem", contact: "+235 987 654 321", address: "Kanem", coordinates: [14.126251, 15.313096] },
  { name: "CAU de Sarh 	Moyen-Chari", contact: "+235 65 99 99 70", address: "Moyen-Chari", coordinates: [9.143735, 18.396743], chef: "Tchaea Ganga" },
  { name: "CAU de Moundou 	Log. Occidental", contact: "+235 65 99 99 70", address: "Logone Occidental", coordinates: [8.552291, 16.075555], chef: "Kerim Saleh" },
  { name: "CAU de Doba 	Logone Oriental", contact: "+235 987 654 321", address: "Logone Oriental", coordinates: [8.676583, 16.83627], chef: "Moudjahid" },



  { name: "CAU de Bongor	Mayo Kebbi Est", contact: "+235 68 88 88 08", address: "Mayo Kebbi Est", coordinates: [10.285483, 15.364561], chef: "Issa Kali" },
  { name: "CAU de Pala 	Mayo-Kebbi Ouest", contact: "+235 987 654 321", address: "Mayo-Kebbi Ouest", coordinates: [9.368332, 14.897408] },
  { name: "CAU de Koumra	Mandoul", contact: "+235 65 99 99 70", address: "Koumra, Mandoul", coordinates: [8.925963, 17.541813], chef: "Tchaea Ganga" },
  { name: "CAU d’Ati,Batha", contact: "+235 65 99 99 70", address: "Ati", coordinates: [13.209170, 18.365090], chef: "Kerim Saleh" },
  { name: "CAU d’Amtiman, 	Salamat", contact: "+235 987 654 321", address: "Amtiman", coordinates: [11.0278, 20.276442], chef: "Moudjahid" },


  { name: "CAU de Goz-Beida, 	Sila", contact: "+235 68 88 88 08", address: "Goz-Beida", coordinates: [12.225985, 21.415804], chef: "Issa Kali" },
  { name: "CAU de Biltine	Wadi Fira", contact: "+235 987 654 321", address: "Wadi Fira", coordinates: [14.524424, 20.915398] },
  { name: "CAU de Bol, 	Lac", contact: "+235 65 99 99 70", address: "Sarh, Lac", coordinates: [13.480053, 14.701547], chef: "Tchaea Ganga" },
  { name: "CAU de Laï	Tandjilé", contact: "+235 65 99 99 70", address: "Laï", coordinates: [9.396220, 16.295735], chef: "Kerim Saleh" },
  { name: "CAU de Moussoro, 	Barh-El-Gazal	", contact: "+235 987 654 321", address: "Moussoro", coordinates: [13.651292, 16.496680], chef: "Moudjahid" },
  { name: "CAU de Bardaï 	Tibesti", contact: "+235 987 654 321", address: "Bardaï", coordinates: [21.35438, 16.99941] },
  { name: "CAU de Faya	Borkou", contact: "+235 65 99 99 70", address: "Faya", coordinates: [17.921003, 19.098695], chef: "Tchaea Ganga" },
  { name: "CAU de Massenya	Chari-Baguirmi", contact: "+235 65 99 99 70", address: "Chari-Baguirmi", coordinates: [11.403701, 16.163600  ], chef: "Kerim Saleh" },
 


 
];


const Map = () => {
  const markerRefs = useRef([]);

  const handleCenterClick = (index) => {
    if (markerRefs.current[index]) {
      markerRefs.current[index].openPopup();
    }
  };

  return (
    <Grid container spacing={2} mt={5} style={{ padding: "20px" }}>
      <Grid item xs={12} md={8}>
        <MapContainer
          center={[12.6392, 14.1186]}
          zoom={6}
          maxBounds={[[7, 13], [24, 24]]} 
          style={{ height: "600px", width: "100%", borderRadius: "8px", marginTop: "20px" }}
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
          {centers.map((center, index) => (
            <Marker
              key={index}
              position={center.coordinates}
              icon={new L.Icon({
                iconUrl: "https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png",
                iconSize: [25, 41],
                iconAnchor: [12, 41],
                popupAnchor: [1, -34],
              })}
              ref={(el) => (markerRefs.current[index] = el)}
            >
              <Popup>
                <Typography variant="subtitle1" gutterBottom>
                  <strong>{center.name}</strong>
                </Typography>
                <Typography variant="body2">
                  {/* <strong>Contact:</strong> {center.contact} */}
                </Typography>
                <Typography variant="body2">
                  <strong>Adresse:</strong> {center.address}
                </Typography>
                {/* {center.chef && (
                  <Typography variant="body2">
                    <strong>Chef de centre:</strong> {center.chef}
                  </Typography>
                )} */}
              </Popup>
            </Marker>
          ))}
        </MapContainer>
      </Grid>

      <Grid item xs={12} md={4} style={{ overflowY: "auto", height: "600px", position: "relative" }}>
        <Card style={{ borderRadius: "8px", height: "100%", width: "100%", border: "2px solid #ddd", boxShadow: "2px 4px 10px rgba(0, 0, 0, 0.1)", position: "relative" }}>
          <CardContent style={{ paddingBottom: 0 }}>
            <Box style={{ position: "sticky", top: 0, backgroundColor: "white", zIndex: 1, padding: "10px", borderBottom: "2px solid #ddd" }}>
              {/* <Typography variant="h6" style={{ fontWeight: "bold" }}></Typography> */}
              <Typography variant="h5" style={{ fontWeight: "bold" }} color="#031B4D">Total Centres: {centers.length} &nbsp; &nbsp; &nbsp; &nbsp;</Typography>
            </Box>
            <Box style={{ maxHeight: "550px", overflowY: "auto", paddingTop: "10px" }}>
              {centers.map((center, index) => (
                <Box
                  key={index}
                  onClick={() => handleCenterClick(index)}
                  sx={{
                    padding: "8px",
                    cursor: "pointer",
                    marginTop: "10px",
                    borderBottom: "1px solid #ddd",
                    '&:hover': { backgroundColor: "#f0f0f0" },
                  }}
                >
                  {/* <Typography variant="body1" style={{ fontWeight: "bold" }}>{center.name}</Typography> */}
                  <Typography variant="body2" color="textSecondary"> {center.name} -{center.address} </Typography>
                </Box>
              ))}
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default Map;
