import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Box,
  useMediaQuery,
  Collapse,
  Paper,
  MenuItem,
} from "@mui/material";
import { Menu as MenuIcon, ExpandMore, ExpandLess } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
const navItems = [
  { label: "Accueil", path: "/" },
  {
    label: "Présentation",
    submenu: [
      { label: "Qui sommes-nous?", path: "/presentation" },
      { label: "Organigramme", path: "/Organigramme" },
      { label: "Mot du DG", path: "/Mot-du-DG" },
    ],
  },
  { label: "Actualités", path: "/Actualites" },
  {
    label: "Médiathèque",
    submenu: [
      { label: "Décret", path: "/documents" },
      { label: "Loi", path: "/loi" },
      { label: "Ordonnance", path: "/Ordonnance" },
      // { label: "Formulaires", path: "/Formulaires" },
      { label: "Galerie", path: "/Galerie" },
      { label: "Rapport", path: "/Rapport" },
    ],
  },
  {
    label: "FAQ & Réclamation",
    submenu: [
      { label: "FAQ", path: "/FAQ" },
      { label: "Réclamation", path: "/Reclamation" },
    ],
  },
  { label: "Contact", path: "/contact" },
];

const Header = () => {
  const [openMenu, setOpenMenu] = useState(null);
  const [openDrawer, setOpenDrawer] = useState(false);
  const isMobile = useMediaQuery("(max-width:900px)");

  const handleMenuOpen = (menuLabel) => {
    setOpenMenu(menuLabel);
  };

  const handleMenuClose = () => {
    setOpenMenu(null);
  };

  return (
    <AppBar
      position="sticky"
      sx={{ bgcolor: "#fff", color: "#142157", boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)" }}
      component={motion.div}
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <Toolbar sx={{ justifyContent: "space-between" }}>
         <Box sx={{ display: "flex", alignItems: "center" }}>
          <img src="/images/logo.png" alt="Logo" style={{ width: 60, height: 60, marginRight: 10 }} />
          <Typography variant="h6" sx={{ fontWeight: "bold", fontSize: "1.5rem" }}>
            {isMobile ? "ANATS" : "Agence Nationale des Titres Sécurisés"}
          </Typography>
        </Box>

        {/*   MOBILE */}
        {isMobile ? (
          <>
            <IconButton color="inherit" onClick={() => setOpenDrawer(true)}>
              <MenuIcon />
            </IconButton>
            <Drawer anchor="left" open={openDrawer} onClose={() => setOpenDrawer(false)}>
              <List sx={{ width: 250 }}>
                {navItems.map((item, index) => (
                  <React.Fragment key={index}>
                    <ListItem button component={Link} to={item.path || "#"} onClick={() => item.submenu && setOpenMenu(item.label)}>
                      <ListItemText   sx={{ fontWeight: "normal", fontSize: "1rem" }}    />
                    </ListItem>
                    {item.submenu && (
                      <Collapse in={openMenu === item.label} timeout="auto" unmountOnExit>
                        <List sx={{ pl: 4 }}>
                          {item.submenu.map((sub, idx) => (
                            <ListItem key={idx} button component={Link} to={sub.path}>
                              <ListItemText  sx={{ fontWeight: "normal", fontSize: "1rem" }}    />
                            </ListItem>
                          ))}
                        </List>
                      </Collapse>
                    )}
                  </React.Fragment>
                ))}
              </List>
            </Drawer>
          </>
        ) : (
          //   DESKTOP
          <Box  sx={{ display: "flex", alignItems: "center" }}>
            {navItems.map((item, index) => (
              <Box key={index} sx={{ position: "relative", mx: 1 }} onMouseEnter={() => handleMenuOpen(item.label)} onMouseLeave={handleMenuClose}>
                <Button
                  color="inherit"
                  component={Link}
                  to={item.path || "#"}
                  sx={{
                    fontWeight: "bold",
                    fontFamily:"Helvetica",
                    position: "relative",
                    "&:hover": {
                      backgroundColor: "transparent",
                      "&:after": {
                        content: '""',
                        position: "absolute",
                        left: 0,
                        bottom: 0,
                        width: "100%",
                        height: "2px",
                        backgroundColor: "#142157",
                      },
                    },
                  }}
                >
                  {item.label}
                  {item.submenu && (openMenu === item.label ? <ExpandLess sx={{ ml: 1 }} /> : <ExpandMore sx={{ ml: 1 }} />)}
                </Button>
                {item.submenu && openMenu === item.label && (
                  <Paper
                    component={motion.div}
                    initial={{ opacity: 0, y: -10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.2 }}
                    sx={{
                      position: "absolute",
                      top: "100%",
                      left: 0,
                      zIndex: 1,
                      minWidth: 200,
                      boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
                      borderRadius: 2,
                      overflow: "hidden",
                      background: "white",
                    }}
                  >
                    {item.submenu.map((sub, idx) => (
                      <MenuItem key={idx} component={Link} to={sub.path} onClick={handleMenuClose}>
                        {sub.label}
                      </MenuItem>
                    ))}
                  </Paper>
                )}
              </Box>
            ))}
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Header;
