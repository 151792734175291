import React, { useState } from "react";
import { Container, Typography, Card, CardContent, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Pagination, Link } from "@mui/material";
import SectionTitle from './SectionTitle'
const documentsData = {
  Décret: [
    
    {
      title: "Décret 0041/PR//2020 ",
      description: "Décret Portant création des comités locaux d’identification des populations.",
      date: "04 fév 2020",
      link: "/docs/Arrete_comite.pdf",
      
    },
    {
      title: "Décret 2094/PR/19 ",
      description: "Décret  portant réglementation de la carte d'identité national.",
      date: "30 dec 2019",
      link: "/docs/Decret_cin.pdf",
    },
    {
      title: "Décret 0041/PR/2020 ",
      description: "Décret Portant ouverture des opérations d’enrôlement dans le Registre national biométrique des populations.",
      date: "04 fév 2020",
      link: "/docs/Decret_enrolement.pdf",
    },
  
    {
      title: "Décret 1943/PR/MSPI/2020 ",
      description: "Décret Portant organisation et fonctionnement de l’Agence nationale des titres sécurisés.",
      date: "22 sept 2020",
      link: "/docs/decret_organisation_anats.pdf",
    },
    {
      title: "Décret 044/PR/2020 ",
      description: "Décret portant réglementation des titres de voyage.",
      date: "04 fév 2024",
      link: "docs/decret_document_voyage.pdf",
    },
    {
      title: "Décret 3142/PR/2019 ",
      description: "Décret Portant modification du décret n° 1943/PR/MSPI/2020.",
      date: "07 oct 2022",
      link: "docs/decret_organisation_anats1.pdf",
    },
  
  ],
  loi: [
    { title: "Loi 2024-01", description: "Loi relative à la protection des données personnelles.", date: "10 jan 2024", link: "#" },
    { title: "Loi 2024-02", description: "Loi sur la cybercriminalité et les infractions numériques.", date: "15 jan 2024", link: "#" }
  ],
  ordonnance: [
    { title: "Ordonnance 2024-01", description: "Ordonnance réglementant l'accès aux services numériques.", date: "20 jan 2024", link: "#" }
  ],
  report: [
    { title: "Rapport 2024-01", description: "Rapport annuel sur la situation des réfugiés.", date: "25 fév 2024", link: "#" }
  ]
,
  other: [
    { title: "Rapport 2024-01", description: "Rapport annuel sur la situation des réfugiés.", date: "25 fév 2024", link: "#" }
  ]
};

const itemsPerPage = 10;

const Documents = ({ documentType = "Décret" }) => {
  const [page, setPage] = useState(1);
  const handleChange = (event, value) => {
    setPage(value);
  };

  const documents = documentsData[documentType] || [];
  const startIndex = (page - 1) * itemsPerPage;
  const selectedDocuments = documents.slice(startIndex, startIndex + itemsPerPage);
  return (
    <Container maxWidth="lg" style={{ marginBottom: "40px",marginTop: "40px" }}>
          
      <Typography   variant="h4" gutterBottom sx={{ fontWeight: "bold", mb: 1 }}>
       
      <SectionTitle text={documentType.charAt(0).toUpperCase() + documentType.slice(1) + "s"} />
</Typography>
               
       {/* </Typography>
            {documentType.charAt(0).toUpperCase() + documentType.slice(1)}s
          </Typography> */}
          <Typography variant="body1" align="center" color="textSecondary" paragraph>
            Consultez et téléchargez les documents officiels 
            {/* relatifs à {documentType === "documents" ? "loi" : "ordonnance"}. */}
          </Typography>
      <Card elevation={5} style={{ padding: "20px", boxShadow: "0px 4px 15px rgba(182, 179, 179, 0.1)", borderRadius: "10px" }}>
        <CardContent>
          
          
          <TableContainer>
            <Table sx={{
    "& .MuiTableRow-root:hover": {
      backgroundColor: "rgba(95, 92, 92, 0.1)"
    }
  }}>
              <TableHead>
                <TableRow >
                  <TableCell style={{ width: 120, fontWeight: 'bold' }}>Numéro</TableCell>
                  <TableCell style={{ width: 80, fontWeight: 'bold' }}>Date</TableCell>
                  <TableCell style={{  fontWeight: 'bold' }}>Titre</TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>Lien</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {selectedDocuments.map((doc, index) => (
                  <TableRow key={index}>
                    <TableCell>{doc.title}</TableCell>
                    <TableCell>{doc.date}</TableCell>
                    <TableCell>{doc.description}</TableCell>
                    <TableCell>
                      <Link href={doc.link} color="primary"  underline="hover">
                        Télécharger
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination
            count={Math.ceil(documents.length / itemsPerPage)}
            page={page}
            onChange={handleChange}
            color="primary"
            style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}
          />
        </CardContent>
      </Card>
    </Container>
  );
};

export default Documents;
