import React from "react";
import { Container, Typography, List, ListItem, Box, Paper } from "@mui/material";
import DoneIcon from '@mui/icons-material/Done';
import { motion } from "framer-motion";
import { useEffect } from "react";
 const data = 
  
[{
    "title": " L'acte de naissance avec  NNI",
    "description": "Le numéro national d’identification (NNI) est un identifiant unique attribué à chaque citoyen et résident. Il est indispensable pour toute demande de titre. Vous pouvez obtenir votre NNI sans avoir à faire une demande de titre spécifique (Citoyen).",
    "downloadLink": "d.pdf",
    "items": [
      "Copie de l’acte de naissance",
      "Trois témoins dont au moins un parent biologique",
    ],
    // "note": "NB : Pour les enfants, NNI des parents ou un certificat de prise en charge."
  },
  {
    "title": "Premiére demande de passeport pour un nouveau-née",
    "items": [
      "Copie de l’extrait de registre de la population",
      "Copie de l’acte de naissance",
      "Une photo 4x4 couleur",
      "Trois témoins dont au moins un parent biologique",
      "Quittance de paiement de valeur de la CIN ou du Passeport"
    ],
    // "note": "NB : Pour les enfants, NNI des parents ou un certificat de prise en charge."
  }]

const EtatCivilStepper = () => {
  useEffect(() => {
       window.scrollTo(0, 0); // Scrolls to the top when the page loads
     }, []);
 
  return (
    <Container maxWidth="lg" sx={{ display: "flex", justifyContent: "center", padding: 4, boxShadow: 0 }}>
      <Paper elevation={5} sx={{ width: "100%", padding: 4, backgroundColor: "#fff", position: "relative", boxShadow: 5 }}>
        <Box sx={{ textAlign: "center", marginBottom: 4, paddingBottom: 2, borderBottom: "2px dashed black" }}>
          {/* <img src="/ logo1.jpg" alt="Agency Logo" style={{ width: "100px", marginBottom: "10px" }} /> */}
          <Typography variant="h4" fontWeight="bold" color="#2B3088" textDecoration="underline">
            Demarche pour l'etat civil
          </Typography>
        </Box>

        <Box sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          opacity: 0.1,
          width: "50%"
        }}>
          <img src="/logo1.jpg" alt="Watermark Logo" style={{ width: "100%" }} />
        </Box>
         
        {data.map((section, index) => (
          <Box key={index} sx={{ marginBottom: 4 }}>
            <Typography  variant="h5" fontWeight="bold" textDecoration="underline" gutterBottom>
           {index+1}  -  {section.title} 
           
           {section.downloadLink && (
        <a 
          href={section.downloadLink} 
          download 
          style={{ marginLeft: 10, color: "#2B3088", textDecoration: "none" }}
        >
          (Télécharger en PDF)
        </a>
      )}
            
            </Typography>
            {section.description && (
              <Typography variant="body1" sx={{ fontStyle: "italic", color: "#555" }} paragraph>
                {section.description}
              </Typography>
            )}
            <List>
              {section.items.map((item, i) => (
                 <ListItem key={i} sx={{ fontSize: 15 }}><DoneIcon /> {item}</ListItem>
              ))}
            </List>
            {section.note && (
              <Typography variant="body1" sx={{ color: "red", fontWeight: "bold" }}>
                {section.note}
              </Typography>
            )}
          </Box>
        ))}

<Box sx={{ marginBottom: 8 }}>
  {/* Title */}
  <Typography
    variant="h5"
    sx={{
      color: "#1D3557",
      marginBottom: 4,
      fontWeight: "bold",
      fontSize: "1.5rem",
      textAlign: "center",
    }}
  >
    État Civil et Partenaires
  </Typography>

   <Typography
    variant="body1"
    sx={{
      color: "#555",
      lineHeight: 1.8,
      fontSize: "1rem",
      textAlign: "justify",
      marginBottom: 4,
    }}
  >
    L'ANATS travaille en étroite collaboration avec plusieurs partenaires nationaux et 
    internationaux pour coordonner les activités de lutte contre l'apatridie et améliorer 
    la gestion des réfugiés. Ces partenariats permettent de renforcer les systèmes d'état civil, 
    d'assurer la protection des droits des citoyens et de faciliter l'intégration des réfugiés.
  </Typography>

   <ul
    style={{
      listStyleType: "none",
      padding: 0,
      margin: 0,
    }}
  >
    {[
      {
        name: "Ministère de l'Intérieur",
        description:
          "Partenaire clé dans la mise en œuvre des politiques d'état civil et de gestion des réfugiés.",
      },
      {
        name: "Haut Commissariat des Nations Unies pour les Réfugiés (HCR)",
        description:
          "Soutien technique et financier pour la protection des réfugiés et la lutte contre l'apatridie.",
      },
      {
        name: "Organisation Internationale pour les Migrations (OIM)",
        description:
          "Collaboration pour la gestion des flux migratoires et l'intégration des réfugiés.",
      },
    ].map((partner, index) => (
      <motion.li
        key={index}
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: index * 0.1 }}
        viewport={{ once: true }}
        style={{
          backgroundColor: "#fff",
          padding: "16px",
          borderRadius: "8px",
          boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
          marginBottom: "12px",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          textAlign: "left",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            fontWeight: "bold",
            color: "#1D3557",
            fontSize: "1.1rem",
            marginBottom: "6px",
          }}
        >
          {partner.name}
        </Typography>
        <Typography
          variant="body1"
          sx={{
            color: "#555",
            fontSize: "0.95rem",
            textAlign: "justify",
          }}
        >
          {partner.description}
        </Typography>
      </motion.li>
    ))}
  </ul>
</Box>


        <Box sx={{ textAlign: "center", marginTop: 4, paddingTop: 2, borderTop: "1px solid #ccc" }}>
          <Typography variant="caption">
            © Agence Nationale des Titres Sécurisés - anats.td
          </Typography>
        </Box>
      </Paper>
    </Container>
  );
};

export default EtatCivilStepper;

 
