import React, { useState } from "react";
import SectionTitle from "../SectionTitle";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Pagination,
  Box,
  CardMedia,
  Modal,
  IconButton,
} from "@mui/material";
import { motion } from "framer-motion";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const galleryData = [
  { id: "2", image: "/images/news/Picture2.png" },
  { id: "3", image: "/images/news/Picture3.png" },
  { id: "4", image: "/images/news/Picture1.png" },
  { id: "5", image: "/images/news/Picture31.png" },
  { id: "6", image: "/images/news/Picture32.png" },
  { id: "7", image: "/images/news/Picture33.png" },
  { id: "8", image: "/images/news/Picture52.png" },
  { id: "9", image: "/images/news/Picture51.png" },
  { id: "10", image: "/images/news/Picture53.png" },
  { id: "11", image: "/images/news/Picture91.png" },
  { id: "12", image: "/images/news/Picture92.png" },
  { id: "13", image: "/images/news/Picture93.png" },
];

const Gallery = () => {
  const [page, setPage] = useState(1);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const itemsPerPage = 12;

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const openModal = (index) => {
    setSelectedIndex(index);
  };

  const closeModal = () => {
    setSelectedIndex(null);
  };

  const nextImages = () => {
    if (selectedIndex < galleryData.length - 3) {
      setSelectedIndex(selectedIndex + 3);
    }
  };

  const prevImages = () => {
    if (selectedIndex >= 3) {
      setSelectedIndex(selectedIndex - 3);
    }
  };

  return (
    <Box sx={{ padding: "20px", maxWidth: "lg", margin: "0 auto", backgroundColor: "#fff" }}>
      <Typography variant="h4" align="center" gutterBottom sx={{ fontWeight: "bold", color: "#1D3557", mb: 4, fontSize: "2rem" }}>
        <SectionTitle text="Nos Galleries" />
      </Typography>
      <Grid container spacing={4}>
        {galleryData.map((news, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <motion.div whileHover={{ scale: 1.05 }} transition={{ type: "spring", stiffness: 300 }}>
              <Card
                sx={{ height: "100%", display: "flex", flexDirection: "column", borderRadius: 2, boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)", transition: "box-shadow 0.3s ease-in-out", "&:hover": { boxShadow: "0 8px 20px rgba(0, 0, 0, 0.2)" } }}
                onClick={() => openModal(index)}
              >
                <CardMedia component="img" height="200" image={news.image} sx={{ objectFit: "cover", borderTopLeftRadius: 8, borderTopRightRadius: 8 }} />
              </Card>
            </motion.div>
          </Grid>
        ))}
      </Grid>
      <Box sx={{ display: "flex", justifyContent: "center", marginTop: 4 }}>
        <Pagination count={Math.ceil(galleryData.length / itemsPerPage)} page={page} onChange={handleChangePage} color="primary" sx={{ "& .MuiPaginationItem-root": { color: "#1D3557" }, "& .Mui-selected": { backgroundColor: "#1D3557 !important", color: "#fff" } }} />
      </Box>
      {/* Modal for Image Zoom */}
      <Modal open={selectedIndex !== null} onClose={closeModal}>
        <Box sx={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", bgcolor: "white", boxShadow: 24, p: 3, borderRadius: 2 }}>
          <IconButton onClick={closeModal} sx={{ position: "absolute", top: 8, right: 8 }}>
            <CloseIcon />
          </IconButton>
          <Grid container spacing={2}>
            {selectedIndex !== null && galleryData.slice(selectedIndex, selectedIndex + 3).map((item, i) => (
              <Grid item xs={4} key={i}>
                <CardMedia component="img" image={item.image} sx={{ width: "100%", height: "300px", objectFit: "cover", borderRadius: 2 }} />
              </Grid>
            ))}
          </Grid>
          <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
            <IconButton onClick={prevImages} disabled={selectedIndex === 0}>
              <ArrowBackIosNewIcon />
            </IconButton>
            <IconButton onClick={nextImages} disabled={selectedIndex >= galleryData.length - 3}>
              <ArrowForwardIosIcon />
            </IconButton>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default Gallery;
