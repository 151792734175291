import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Breadcrumbs as MUIBreadcrumbs, Typography } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import "./Breadcrumbs.css";

const Breadcrumbs = () => {
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);
  return (
    <div className="breadcrumbs-container">
      <MUIBreadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
         {/* Hide the home icon  */}
         {pathnames.length > 0 && (
          <Link to="/" className="breadcrumb-link">
            <HomeIcon fontSize="small" sx={{ verticalAlign: "middle" }} /> Accueil
          </Link>
        )}

        {pathnames.map((path, index) => {
          const to = `/${pathnames.slice(0, index + 1).join("/")}`;
          const formattedPath = path.replace(/-/g, " ").replace(/\b\w/g, (c) => c.toUpperCase());
          const formattedPath1 = path.replace(/\d+/g, "").trim().replace(/-/g, " ").toLowerCase().replace(/^./, (c) => c.toUpperCase());

          return index === pathnames.length - 1 ? (
            <Typography key={to} className="breadcrumb-current">
              {formattedPath}
            </Typography>
          ) : (
             <Typography key={to} className="breadcrumb-current">
            {formattedPath}
          </Typography>
          );
        })}
      </MUIBreadcrumbs>
    </div>
  );
};

export default Breadcrumbs;
