import React from "react";
import SectionTitle from "../SectionTitle";
import { motion } from "framer-motion";
import { Box,Button, Container, Typography, Grid, Paper } from "@mui/material";
import { useNavigate } from "react-router-dom";

const PresentationPage = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/Presentation");  
    window.scrollTo({ top: 0, behavior: "smooth" });  
  };
  return (
    <Box
      sx={{
        position: "relative",
        background: "url('/images/vector-bg.svg') no-repeat center center",
        backgroundSize: "cover",
        paddingTop: 6,
        paddingBottom: 6,
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={6} alignItems="center">
           <Grid item xs={12} md={12}>
            <motion.div
              initial={{ opacity: 0, x: -50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.8 }}
            >
              <SectionTitle text="Présentation" />
              <Typography
                variant="body1"
                sx={{
                  color: "#555",
                  lineHeight: 1.8,
                  fontSize: "1rem",
                  textAlign: "justify",
                }}
              >
                Il est créé en République du Tchad, une <b>Agence Nationale des Titres Sécurisés (ANATS)</b>. 
                L’ANATS est un établissement Public, doté de la personnalité morale et de l’autonomie de gestion.
                <br/><br/>
                Elle est responsable de la production, de la gestion et de la sécurisation des documents officiels tels que les passeports, 
                les cartes d'identité et autres titres sécurisés. Notre mission principale est d'assurer la sécurité et l'intégrité des données personnelles 
                des citoyens tout en offrant des services modernes et efficaces.
                <br/>
                {/* <a href="/presentation" style={{ color: "#0b89e3", fontWeight: "bold" }}>Lire Plus</a> */}
              
                <Button
      onClick={handleClick}
      variant="contained"
      sx={{
        background: "linear-gradient(45deg, #1e88e5, #42a5f5)",
        color: "white",
        fontSize: "18px",
        fontWeight: "bold",
        textTransform: "none",
        borderRadius: "10px",
        px: 4,
        py: 1.5,
        boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.3)",
        transition: "all 0.3s ease-in-out",
        "&:hover": {
          background: "linear-gradient(45deg, #1565c0, #1e88e5)",
          transform: "scale(1.05)",
        },
      }}
    >
      Lire Plus
    </Button>
              </Typography>
            </motion.div>
          </Grid>

           {/* <Grid item xs={12} md={6}>
            <motion.div
              initial={{ opacity: 0, x: 50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.8 }}
            >
              <Paper elevation={4} sx={{ overflow: "hidden", borderRadius: "12px" }}>
                <video width="100%" autoPlay loop muted>
                  <source src="https://www.youtube.com/watch?v=pYwsU96w1RY" type="video/mp4" />
                  Votre navigateur ne supporte pas la lecture de vidéos.
                </video>
              </Paper>
            </motion.div>
          </Grid> */}
        </Grid>
      </Container>
    </Box>
  );
};

export default PresentationPage;