// src/pages/Home.js
import React from "react";
import CarouselComponent from "../components/Carousel/Carousel";
 import Results from "../components/Results";
import Map from "../components/Map";
import Partners from "../components/Partners/Partners";
import PresentationPage from '../components/Presentation/PresentationPage'
import CEOMessageShort from '../components/CEOMessageShort'
import Gallery from '../components/Gallery/Gallery'
import  ServicesGrid  from '../components/Services/ServicesGrid'

 const Home = () => {
  return (
    <div>
      <CarouselComponent />
      <div className="home-container">
                <ServicesGrid />
        
        <PresentationPage />
        <Results />
        <CEOMessageShort/>

        <Map />
        <Partners/>
        {/* <Gallery/> */}
        </div>
    </div>
  );
};

export default Home;
