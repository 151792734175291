import React from "react";
import { Container, Grid, Card, CardActionArea, CardMedia, CardContent, Typography, Button } from "@mui/material";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import SectionTitle from './SectionTitle'
const forms = [
  { title: "Passport Form", file: "passport_form.pdf" },
  { title: "ID Card Form", file: "id_card_form.pdf" },
  { title: "Enrollment Form", file: "enrollment_form.pdf" },
  { title: "Other Form", file: "other_form.pdf" }
];

const DownloadableForms = () => {
  const handleDownload = (file) => {
    const link = document.createElement("a");
    link.href = `/downloads/${file}`;  
    link.download = file;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Container sx={{marginTop:10}} >
      <Typography variant="h4" align="center" gutterBottom>
       <SectionTitle text="Downloadable Forms"/>
      </Typography>
      <Grid container spacing={3}>
        {forms.map((form, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <Card>
              <CardActionArea onClick={() => handleDownload(form.file)}>
                <CardMedia
                  component="img"
                  height="140"
                  image="https://cdn-icons-png.flaticon.com/512/337/337946.png"
                  alt={form.title}
                />
                <CardContent>
                  <Typography variant="h6" align="center">
                    {form.title}
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    startIcon={<PictureAsPdfIcon />}
                  >
                    Télécharger
                  </Button>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default DownloadableForms;
