import React from "react";
import { Card, CardContent, CardMedia, Typography, Grid, Container } from "@mui/material";
import SectionTitle from "../SectionTitle";
import { useNavigate } from "react-router-dom";

const services = [
   {
    title: "Citoyen",
    description: "Guidance through the passport renewal process.",
    imageUrl: "/images/p.png",
    path: "/citoyen/Pieces",
  },
  {
     title: "Résident",
    description: "Assistance with applying for your national ID .",
    imageUrl: "/images/pr.png",
    path: "/citoyen/resident",
  },
  {
 
    title: " Permis de conduire et Immatriculation ",
    description: "Services to authenticate your official documents.",
    imageUrl: "/images/pi.jpeg",
    path: "/citoyen/permis-de-conduire-et-immatriculation/",

  },
  {
     title: "Etat civil",
    description: "Help with visa applications for various countries.",
    imageUrl: "/images/pe.jpeg",
    path: "/Etat-Civil",
},

  
];

const ServicesGrid = () => {
  const navigate = useNavigate();
//92134A
  return (
    <Container  maxWidth="1200px" sx={{ textAlign: "center",backgroundColor:"#fff", mt: 5 }}>
      <SectionTitle text="Votre Démarche Concerne..."/>
      <Grid container spacing={3} justifyContent="center">
        {services.map((service, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <Card
              sx={{ cursor: service.path ? "pointer" : "default"  ,transition: 'transform 0.3s ease-in-out',
                '&:hover': { transform: 'scale(1.07)' }}}
              onClick={() => service.path && navigate(service.path)}
              
            >
              <CardMedia component="img" height="140" image={service.imageUrl} alt={service.title} />
              <CardContent>
                <Typography variant="h6" fontWeight="bold">
                  {service.title}
                </Typography>
            
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default ServicesGrid;
