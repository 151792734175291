import React from "react";
import { useParams } from "react-router-dom";
import { Box, Typography, Container, Grid } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import FacebookPageEmbed from './FacebookPageEmbed'
import { newsData } from "../../data/newsData";

const NewsDetail = () => {
  const { id } = useParams();
  const news = newsData.find((item) => item.id === parseInt(id));

  if (!news) {
    return <Typography variant="h6">Actualité non trouvée.</Typography>;
  }

  return (
    <Container maxWidth={false} sx={{ mt: 4, px: { xs: 2, md: 12 } }}>
      <Grid container spacing={3}>
        {/* Section gauche - Date et lieu */}
        {/* <Grid item xs={12} md={3}>
          <Box sx={{ p: 2, border: "1px solid #ddd", borderRadius: 2, background: "#f9f9f9" }}>
            <Typography variant="body1" sx={{ color: "#555" }}>
              <strong>Date:</strong> {news.date} <br />
              <strong>Lieu:</strong> {news.place}
            </Typography>
          </Box>
        </Grid> */}

         <Grid item xs={12} md={8}>
          <Typography variant="h4" sx={{ fontWeight: "bold", color: "#1D3557", mb: 2 }}>
            {news.title}
          </Typography>
          <Swiper spaceBetween={10} slidesPerView={1} pagination={{ clickable: true }} modules={[Pagination]}>
            {news.images.map((image, index) => (
              <SwiperSlide key={index}>
                <Box
                  component="img"
                  src={image}
                  alt={`Slide ${index}`}
                  sx={{ width: "100%", height: 400, objectFit: "cover", borderRadius: 2 }}
                />
              </SwiperSlide>
            ))}
          </Swiper>


       <Box sx={{ mt: 0 }}>
      <Box sx={{ p:1, border: "1px solid #ddd", borderRadius: 1, background: "#fff" }}>
            <Typography variant="body1" sx={{ color: "#555" }}>
              <strong>Date:</strong> {news.date}  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>Lieu:</strong> {news.place}
            </Typography>
          </Box>
        {news.description.split("\n").map((paragraph, index) => (
          <Typography key={index} variant="body1" sx={{ mb: 2, lineHeight: 1.8, textAlign: "justify", color: "#333" }}>
            {paragraph}
          </Typography>
        ))}
      </Box>
        </Grid>

         <Grid item xs={12} md={3}>
          <Box sx={{ p: 2, border: "1px solid #ddd", borderRadius: 2, background: "#fff" }}>
 
            <FacebookPageEmbed/>

           </Box>
        </Grid>
      </Grid>


    </Container>
  );
};

export default NewsDetail;
