


import React, { useState } from "react";
import {
  Container,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SectionTitle from "./SectionTitle";


const faqData = [
  {
    question: "Quels sont les documents nécessaires pour obtenir une pièce d’identité ?",
    answer: "Les documents requis dépendent du type de pièce demandée (CIN, passeport, permis de conduire, carte grise, etc.). Vous pouvez consulter la liste détaillée en sélectionnant la catégorie concernée sur notre site web.",
  },
  {
    question: "Où puis-je déposer ma demande de titre sécurisé ?",
    answer: "Les demandes doivent être déposées dans l’un des centres ANATS de votre région. Vous pouvez consulter la liste et les adresses de nos centres sur notre site web."  },
  {
    question: "Comment suivre l’état d’avancement de ma demande ?",
    answer: "Pour suivre l’avancement de votre demande, veuillez vous rapprocher du centre ANATS le plus proche avec votre récépissé de dépôt."  },
  {
    question: "Que faire en cas de perte ou de vol de mon titre sécurisé ?",
    answer: "En cas de perte ou de vol, vous devez obligatoirement faire une déclaration de perte auprès des autorités compétentes (police ou gendarmerie). Ce document est nécessaire pour effectuer une demande de renouvellement auprès de l’ANATS."  },
  {
    question: "Quel est le délai de traitement pour obtenir un titre sécurisé ?",
    answer: "Le délai de traitement varie en fonction du type de titre demandé. En moyenne, la délivrance prend une semaine, mais ce délai peut être plus long en fonction du volume des demandes et des vérifications nécessaires."  
  },
  {
    question: "Comment contacter l’ANATS pour plus d’informations ?",
    answer: "Vous pouvez nous contacter via notre formulaire en ligne disponible sur le site web, par téléphone aux numéros indiqués, ou en vous rendant directement dans l’un de nos centres."  },
];
const FAQ = () => {
  const [expanded, setExpanded] = useState(null);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : null);
  };

  return (
    <Container maxWidth="lg" sx={{ py: 5 }}>
      <Typography variant="h4" sx={{ mb: 5 }} align="center" fontWeight="bold">
        <SectionTitle text="Foire aux questions (FAQ)" />
      </Typography>

      {faqData.map((faq, index) => (
        <Accordion 
          key={index} 
          expanded={expanded === index} 
          onChange={handleChange(index)} 
          sx={{ mb: 2, borderRadius: "8px", boxShadow: expanded === index ? 3 : 1 }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">
              {index + 1}. {faq.question}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{faq.answer}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </Container>
  );
};

export default FAQ;

