import React from "react";
import { Container, Typography, List, ListItem, Box, Paper } from "@mui/material";
import DoneIcon from '@mui/icons-material/Done';
import { useParams } from "react-router-dom";
import { useEffect } from "react";
const data = {

"Pieces":[
{
    "title": " Pour avoir son NNI",
    "description": "Le numéro national d’identification (NNI) est un identifiant unique attribué à chaque citoyen et résident. Il est indispensable pour toute demande de titre. Vous pouvez obtenir votre NNI sans avoir à faire une demande de titre spécifique (Citoyen).",
    "downloadLink": "d.pdf",
    "items": [
      "Copie de l’acte de naissance",
      "Une photo 4x4 couleur",
      "Une photo 4x4 couleur",
      "Justificatif de profession",
      "Trois témoins dont au moins un parent biologique",
      
      
    ],
    "note": "NB : Pour les enfants, NNI des parents ou un certificat de prise en charge."
  },
  {
    "title": "Première demande d'une carte d'Identité ou d'un passeport",
    "description": "Ces justificatifs concernent les citoyens ayant déjà leur NNI (Numéro National d’Identification) et souhaitant faire une première demande de carte d’identité ou de passeport.",
    "items": [
      "Copie de l’extrait de registre de la population",
      "Copie de l’acte de naissance",
      "Une photo 4x4 couleur",
      "Justificatif de profession",
      "Trois témoins dont au moins un parent biologique",
      "Quittance de paiement de valeur de la CIN ou du Passeport"
    ],
    "note": "NB : Pour les enfants, NNI des parents ou un certificat de prise en charge."
  },
 {
    "title": "Pour un renouvellement d'une carte d'Identité ou d'un passeport",
    "description": "Ces justificatifs concernent les citoyens disposant déjà d’un NNI (Numéro National d’Identification) et souhaitant renouveler ou remplacer leur carte d’identité ou leur passeport.",
    "items": [
      "Copie de l’acte de naissance",
      "Une photo 4x4 couleur",
      "Justificatif de profession",
      "Trois témoins dont au moins un parent biologique",
      "Quittance de paiement de valeur de la CIN ou du Passeport",
      "Ancien titre expiré"
    ]
  },
  {
    "title": "Pour un remplacement d'une carte d'Identité ou d'un passeport",
    "items": [
      "Copie de l’acte de naissance",
      "Une photo 4x4 couleur",
      "Justificatif de profession",
      "Trois témoins dont au moins un parent biologique",
      "Si le titre est perdu ou volé : Déclaration de perte ou de vol",
      "Pour tout autre motif : présenter le titre ou tout autre justificatif approprié.",
      "Quittance de paiement de valeur de la CIN ou du Passeport"
    ]
  },
],
"resident":[{
      "title":"Pour un résident travailleur",
      "downloadLink": "d.pdf",

      "items":[
          "Une demande adressée au directeur de l’immigration et l’émigration (avec l’entête ou le logo de l’entreprise)",
           "Une autorisation d’emploi (ONAPE)",
           "Copie du passeport valide",
           "Une photo 4x4 couleur",
           "Raison de présence au Tchad",
           "Une quittance de paiement selon la tarification en vigueur"

  ]},
 {     "title": "Pour un résident diplomate",
       "items":[ "Une demande adressée au directeur de l’immigration et l’émigration",
       "Copie du passeport diplomate ou service valide",
       "Une photo 4x4 couleur",
       "Raison de présence au Tchad"]
},
{
      "title":"Pour un renouvellement de carte résident",
      "items":[
          " Une demande adressée au directeur de l’immigration et l’émigration",
          "(avec l’entête ou le logo de l’entreprise)",
          "Une autorisation d’emploi (ONAPE)",
          "Copie du passeport valide",
          "Une photo 4x4 couleur",
          "Raison de présence au Tchad",
          "Une quittance de paiement selon la tarification en vigueur"
  ]
},{
  "title":"Pour un remplacement de carte résident",
  "items":[
           "Une demande adressée au directeur de l’immigration et l’émigration",
           "(avec l’entête ou le logo de l’entreprise)",
           "Une autorisation d’emploi (ONAPE)",
           "Copie du passeport valide",
           "Une photo 4x4 couleur",
           "Raison de présence au Tchad",
           "Une quittance de paiement selon la tarification en vigueur",
  ]
}
],
"permis-de-conduire-et-immatriculation" : [
  {
"title" : "Pour un renouvellement de permis",
"downloadLink": "d.pdf",
"items" : [
          "Copie de l’extrait de registre de la population",
          "Copie de l’ancien permis ou du permis provisoire expiré",
          "Une photo 4x4 couleur",
          "Bulletin de registre sanguin",
          "Une quittance de paiement selon la tarification en vigueur"
]},
{
"title":"Pour un remplacement de permis",
"items" : [
           "Copie de l’extrait de registre de la population",
           "Copie de l’ancien permis ou du permis provisoire expiré",
           "Une photo 4x4 couleur",
           "Bulletin de registre sanguin",
           "Une quittance de paiement selon la tarification en vigueur",
           "Si le permis est perdu ou volé : Déclaration de perte ou de vol",
           "Pour tout autre motif : présenter le permis ou tout autre justificatif approprié."
]
  },


  {
    "title" : "Pour une demande de certificat d’immatriculation",
    "items" : [
             "Personne physique : Extrait du Registre de la Population (NNI)",
             "Personne morale : Déclaration de douane",
             "Certificat d’immatriculation délivré par la douane (CI)",
             "Attestation de cession ou acte de vente (si la déclaration en douane n’est",
             "pas au nom de l’usager)",
             "Une quittance de paiement selon la tarification en vigueur"
    ]},
    {
    "title": "Pour un renouvellement de carte grise",
    "items":[
            "Personne physique : Extrait du Registre de la Population (NNI)",
            "Personne morale : Registre du commerce ou autorisation de fonctionnement",
            "Déclaration de douane",
            "Certificat d’immatriculation délivré par la douane (CI)",
            "Attestation de cession ou acte de vente (si la déclaration en douane n’est",
            "pas au nom de l’usager)",
            "Une quittance de paiement selon la tarification en vigueur",
    
    ]
  },
  {
  "title" :" Pour un remplacement de carte grise",
  "items": [
         "Personne physique : Extrait du Registre de la Population (NNI)",
         "Personne morale : Registre du commerce ou autorisation de fonctionnement",
         "Déclaration de douane",
         "Certificat d’immatriculation délivré par la douane (CI)",
         "Attestation de cession ou acte de vente (si la déclaration en douane n’est pas au nom de l’usager)",
         "Si le titre est perdu ou volé : Déclaration de perte ou de vol de la carte grise",
         "Pour tout autre motif : présenter le titre ou tout autre justificatif approprié.",
         "Une quittance de paiement selon la tarification en vigueur",
  ]
}
]



};
 

const CitizenStepper = () => {

   useEffect(() => {
      window.scrollTo(0, 0); // Scrolls to the top when the page loads
    }, []);
  const { category } = useParams();
 const sections = data[category] || [];
console.log(category)
  return (
    <Container maxWidth="lg" sx={{ display: "flex", justifyContent: "center", padding: 4, boxShadow: 0 }}>
      <Paper elevation={5} sx={{ width: "100%", padding: 4, backgroundColor: "#fff", position: "relative", boxShadow: 5 }}>
        <Box sx={{ textAlign: "center", marginBottom: 4, paddingBottom: 2, borderBottom: "2px dashed black" }}>
          <img src="/logo1.jpg" alt="Agency Logo" style={{ width: "100px", marginBottom: "10px" }} />
          <Typography variant="h4" fontWeight="bold" color="#2B3088" textDecoration="underline">
            Agence Nationale des Titres Sécurisés - anats.td
          </Typography>
        </Box>

        <Box sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          opacity: 0.1,
          width: "50%"
        }}>
          <img src="/logo1.jpg" alt="Watermark Logo" style={{ width: "100%" }} />
        </Box>
         <Box>
         <Typography className="text-center" variant="h5" fontWeight="bold" textDecoration="underline" gutterBottom>
         Pièces justificatives </Typography>
         </Box>
        {sections.map((section, index) => (
          <Box key={index} sx={{ marginBottom: 4 }}>
            <Typography  variant="h5" fontWeight="bold" textDecoration="underline" gutterBottom>
           {index+1}  -  {section.title} 
           
           {section.downloadLink && (
        <a 
          href={section.downloadLink} 
          download 
          style={{ marginLeft: 10, color: "#2B3088", textDecoration: "none" }}
        >
          (Télécharger en PDF)
        </a>
      )}
            
            </Typography>
            {section.description && (
              <Typography variant="body1" sx={{ fontStyle: "italic", color: "#555" }} paragraph>
                {section.description}
              </Typography>
            )}
            <List>
              {section.items.map((item, i) => (
                 <ListItem key={i} sx={{ fontSize: 15 }}><DoneIcon /> {item}</ListItem>
              ))}
            </List>
            {section.note && (
              <Typography variant="body1" sx={{ color: "red", fontWeight: "bold" }}>
                {section.note}
              </Typography>
            )}
          </Box>
        ))}

        <Box sx={{ textAlign: "center", marginTop: 4, paddingTop: 2, borderTop: "1px dashed #ccc" }}>
          <Typography variant="caption">
            © Agence Nationale des Titres Sécurisés - anats.td
          </Typography>
        </Box>
      </Paper>
    </Container>
  );
};

export default CitizenStepper;

 
