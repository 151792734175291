import React from "react";
import { Typography } from "@mui/material";
import { motion } from "framer-motion";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation, Autoplay } from "swiper/modules";
 
const slides = [
  {
    // title: "Fais toi Enroler ",
    // subtitle: "Benficie des avantages sociaux de l'etat du Tchad.",
    image: "/images/page-01.jpg",
  },
  {
    // title: "Avec mon nni",
    // subtitle: "Aucun Risque de fraude.",
    image: "/images/page-02.jpg",
  },
  {
    // title: "ANATS au ",
    // subtitle: "Designing the future with you, one step at a time.",
    image: "/images/page-03.jpg",
  },
];

const CarouselComponent = () => {
  return (
    <div style={{ 
      position: "relative", 
      width: "100%", 
      height: "100vh",
      maxHeight: "100vh",
      overflow: "hidden"
    }}>
      <Swiper
        direction="vertical"
        pagination={{ clickable: true }}
        navigation={true}
        autoplay={{ delay: 3000, disableOnInteraction: true }}
        modules={[Pagination, Navigation, Autoplay]}
        className="mySwiper"
        style={{ 
          width: "100%", 
          height: "100%",
          maxHeight: "100vh"
        }}
      >
        {slides.map((slide, index) => (
          <SwiperSlide 
            key={index} 
            style={{ 
              display: "flex", 
              alignItems: "center", 
              justifyContent: "center", 
              flexDirection: "column", 
              color: "white", 
              position: "relative",
              height: "100vh"
            }}
          >
            <img 
              src={slide.image} 
              alt={`Slide ${index + 1}`} 
              style={{ 
                position: "absolute", 
                width: "100%", 
                height: "100%", 
                objectFit: "cover", 
                zIndex: -1 
              }} 
            />
            {/* <motion.div initial={{ y: 100, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ duration: 0.8 }} style={{ textAlign: "center", backgroundColor: "rgba(11, 137, 227, 0.5)", padding: "2rem", borderRadius: "12px" }}>
              <Typography variant="h3" align="center" fontWeight="bold">
                {slide.title}
              </Typography>
              <Typography variant="h6" align="center" mt={2}>
                {slide.subtitle}
              </Typography>
            </motion.div> */}
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default CarouselComponent;
