// import React from "react";
// import { Container, Box, Typography, Avatar, IconButton } from "@mui/material";
// import LinkedInIcon from "@mui/icons-material/LinkedIn";
// import TwitterIcon from "@mui/icons-material/Twitter";
// import FacebookIcon from "@mui/icons-material/Facebook";

// const CEOMessage = () => {
//   return (
//     <Container  maxWidth="lg" sx={{   mt: 4, p: 3 }}>
//       <Box
//         sx={{
//           display: "flex",
//           flexDirection: { xs: "column", sm: "row" },
//           gap: 4,
//           alignItems: "flex-start",
//         }}
//       >
//         <Box
//           sx={{
//             display: "flex",
//             flexDirection: "column",
//             alignItems: "center",
//             minWidth: "180px",
//           }}
//         >
//           <Avatar
//             src="/images/dg.jpeg"
//             alt="MAHAMAT OUMAR KESSOU"
//             sx={{
//               width: 400,
//               height: 400,
//               mb: 2,
//               borderRadius: "12px", // Bordure arrondie
//               border: "2px solid #ddd", // Bordure
//             }}
//           />
//           <Typography variant="h5" component="div" sx={{ fontWeight: "bold" }}>
//             MAHAMAT OUMAR KESSOU
//           </Typography>
//           <Typography variant="h6"   color="text.secondary">
//             Directeur Général
//           </Typography>
//           <Box sx={{ mt: 1, display: "flex", gap: 1 }}>
//             <IconButton
//               aria-label="LinkedIn"
//               component="a"
//               href="https://www.linkedin.com/in/ "
//             >
//               <LinkedInIcon />
//             </IconButton>
//             <IconButton
//               aria-label="Twitter"
//               component="a"
//               href="https://twitter.com/ "
//             >
//               <TwitterIcon />
//             </IconButton>
//             <IconButton
//               aria-label="Facebook"
//               component="a"
//               href="https://www.facebook.com/ "
//             >
//               <FacebookIcon />
//             </IconButton>
//           </Box>
//         </Box>

//         <Box sx={{ flex: 7 }}>
//           <Typography
//             variant="body1"
//             sx={{
//               fontSize: "18px",
//               textAlign: "justify",
//               lineHeight: "1.8",
//             }}
//           >
//             Depuis plusieurs décennies, le Tchad réforme son administration,
//             notamment l'état civil et l'identification. Les défaillances du
//             système actuel se manifestent par un faible taux d'enregistrement
//             des faits d'état civil, une mauvaise gestion des archives et
//             l'absence de données statistiques vitales. Par ailleurs, les
//             menaces sécuritaires, la prolifération de faux documents et
//             l'usurpation d'identité rendent urgente la mise en place d'un
//             système fiable.
//           </Typography>

//           <Typography
//             variant="body1"
//             sx={{
//               fontSize: "18px",
//               textAlign: "justify",
//               lineHeight: "1.8",
//               mt: 2,
//             }}
//           >
//             Face à ces enjeux, le Gouvernement a créé <b>l'Agence Nationale des
//             Titres Sécurisés (ANATS) en 2016 </b> . Celle-ci a mis en place le
//             Système Intégré de Gestion des Populations et des Titres Sécurisés
//             (SIGPTS), permettant l'enrôlement biométrique et la génération d'un
//            <b>Numéro National d'Identification (NNI) unique</b> . L'ANATS gère
//             également le <b>Registre National Biométrique des Populations (RNBP)</b>,
//             la production et la sécurisation des documents d'état civil et
//             d'identification, tels que les cartes nationales d'identité,
//             passeports et permis de conduire.
//           </Typography>

//           <Typography
//             variant="body1"
//             sx={{
//               fontSize: "18px",
//               textAlign: "justify",
//               lineHeight: "1.8",
//               mt: 2,
//             }}
//           >
//             Aujourd'hui, le SIGPTS couvre neuf des dix communes de <b>N'Djamena</b> et
//             vingt-deux chefs-lieux de province. Initialement conçu pour une
//             population de <b>15 millions d'habitants en 2017</b>, son moteur
//             biométrique performant assure une vérification rapide des données
//             en 12 secondes. Les informations sont protégées par une
//            <b>infrastructure cryptographique (PKI)</b> , garantissant la sécurité des
//             transferts de données entre les différentes stations de travail et
//             le site central.
//           </Typography>

//           <Typography
//             variant="body1"
//             sx={{
//               fontSize: "18px",
//               textAlign: "justify",
//               lineHeight: "1.8",
//               mt: 2,
//             }}
//           >
//             Malgré ces avancées, des défis subsistent, notamment en termes
//             d'infrastructures numériques pour étendre le réseau et faciliter
//             l'accès aux services d'état civil. Dans cette optique, l'ANATS
//             collabore avec <b>TECHNIDEV </b>  pour développer <b>TASDJIL</b>, une solution
//             digitale adaptée au contexte tchadien. Cette solution, déployée
//             dans 91 hôpitaux, permet d'obtenir un acte de naissance en moins de
//             20 minutes après déclaration.
//           </Typography>

//           <Typography
//             variant="body1"
//             sx={{
//               fontSize: "18px",
//               textAlign: "justify",
//               lineHeight: "1.8",
//               mt: 2,
//             }}
//           >
//             Par ailleurs, <b>l'ANATS</b> organise des missions mobiles d'enrôlement
//             pour la diaspora et envisage d'ouvrir des bureaux dans les
//             représentations diplomatiques tchadiennes afin de simplifier les
//             demandes de titres et de visa.
//           </Typography>

//           <Typography
//             variant="body1"
//             sx={{
//               fontSize: "18px",
//               textAlign: "justify",
//               lineHeight: "1.8",
//               mt: 2,
//             }}
//           >
//             L'enrôlement biométrique de la population est une nécessité
//             impérieuse. Son financement reste un enjeu crucial pour la réussite
//             du projet. Le développement d'un recensement à vocation état civil
//             (RAVEC) permettrait d'accélérer cette dynamique et de renforcer la
//             gouvernance numérique du pays. Avec un engagement soutenu du
//             gouvernement et de ses partenaires, l'ANATS ambitionne de doter le
//             Tchad d'un système d'identification moderne, fiable et
//             interopérable, au service du développement et de la sécurité
//             nationale.
//           </Typography>
//         </Box>
//       </Box>
//     </Container>
//   );
// };

// export default CEOMessage;






import React from "react";
import { Container, Box, Typography, Avatar, IconButton } from "@mui/material";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import  SectionTitle from './SectionTitle'
import { useEffect } from "react";
const CEOMessage = () => {
 
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top when the page loads
  }, []);

  return (
    <Container

    
      maxWidth="lg"
      sx={{
        mt: 4,
        p: 3,
      
      }}
    >
<SectionTitle text="Mot du DG"/>
      <Box
        sx={{

          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          gap: 4,
          alignItems: "flex-start",
          backgroundColor: "rgba(255, 255, 255, 0.9)", // Light overlay for readability
          padding: 3,
          borderRadius: "12px",
        }}
      >

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            minWidth: "180px",
          }}
        >
          <Avatar
            src="/images/dg.jpeg"
            alt="MAHAMAT OUMAR KESSOU"
            sx={{
              width: 400,
              height: 400,
              mb: 2,
              borderRadius: "12px",
              border: "2px solid #ddd",
            }}
          />
          <Typography variant="h5" component="div" sx={{ fontWeight: "bold" }}>
           M. MAHAMAT OUMAR KESSOU 
          </Typography>
          <Typography variant="h6" color="text.secondary">
            Directeur Général
          </Typography>
          <Box sx={{ mt: 1, display: "flex", gap: 1 }}>
            <IconButton
              aria-label="LinkedIn"
              component="a"
              href="https://www.linkedin.com/in/"
            >
              <LinkedInIcon />
            </IconButton>
            <IconButton aria-label="Twitter" component="a" href="https://twitter.com/">
              <TwitterIcon />
            </IconButton>
            <IconButton aria-label="Facebook" component="a" href="https://www.facebook.com/share/16PZa2LcUJ/?mibextid=wwXIfr" target="_blank">
              <FacebookIcon />
            </IconButton>
          </Box>
        </Box>

        
        <Box sx={{ flex: 7 }}>
          <Typography
            variant="body1"
            sx={{
              fontSize: "18px",
              textAlign: "justify",
              lineHeight: "1.8",
            }}
          >
          <b>Chers citoyens, partenaires et visiteurs,</b> 
          </Typography>

          <Typography
            variant="body1"
            sx={{
              fontSize: "18px",
              textAlign: "justify",
              lineHeight: "1.8",
              mt: 2,
            }}
          >
           C’est avec un immense plaisir que je vous souhaite la bienvenue sur le site officiel de <b>’Agence Nationale des Titres Sécurisés (ANATS). </b>l Notre mission consiste à garantir la délivrance de documents officiels sécurisés et fiables, au service des citoyens et des institutions de notre pays.
          </Typography>

          <Typography
            variant="body1"
            sx={{
              fontSize: "18px",
              textAlign: "justify",
              lineHeight: "1.8",
              mt: 2,
            }}
          >
            Dans un monde où la sécurité et l’authenticité des documents officiels sont primordiales, l’ANATS s’engage à fournir des services modernes, efficaces et accessibles. Créée par l’Ordonnance N°001/PR/2016, notre Agence veille à la protection des identités et à la sécurisation des titres officiels tels que les passeports, les cartes d’identité et les permis de conduire. <br/>
Pour atteindre cet objectif, l’Agence a mis en place <b> le Système Intégré de Gestion des Populations et des Titres Sécurisés (SIGPTS). </b>Ce dispositif permet, après l’enrôlement et la déduplication, d’attribuer à chaque individu (citoyen, réfugié, étranger résident ou en transit sur le territoire national) un <b> Numéro National d’Identification (NNI)</b>  unique et infalsifiable, généré de manière aléatoire. 

          </Typography>

          <Typography
            variant="body1"
            sx={{
              fontSize: "18px",
              textAlign: "justify",
              lineHeight: "1.8",
              mt: 2,
            }}
          >
           Le SIGPTS permet de couvrir l’ensemble du territoire national, notamment les chefs-lieux des provinces, les chefs-lieux des départements et toutes les communes du pays, parcourir les villages et les couloirs de transhumance à l’effet de pouvoir constituer le Registre National Biométrique des Populations (RNBP). Cette base, une fois constituée sera le socle d’une bonne gouvernance locale efficace et efficiente et servira de base de planification optimale et efficiente des politiques publiques. <br/>
Aujourd’hui, le SIGPTS couvre neuf (9) communes sur les dix (10) de la ville de N’Djamena et Vingt Deux (22) chefs-lieux de province.

          </Typography>

          <Typography
            variant="body1"
            sx={{
              fontSize: "18px",
              textAlign: "justify",
              lineHeight: "1.8",
              mt: 2,
            }}
          >
           Par ailleurs, l’ANATS est responsable de la gestion du Registre National Biométrique des Populations (RNBP). Ce registre est couplé à un système de gestion de l’état civil qui facilite la production et la sécurisation des documents officiels, notamment les actes d’état civil (naissance, mariage, divorce et décès), les passeports (ordinaires, de service et diplomatiques), la carte nationale d’identité, la carte de résident, le permis de conduire et la certification d’immatriculation.
          </Typography>

          <Typography
            variant="body1"
            sx={{
              fontSize: "18px",
              textAlign: "justify",
              lineHeight: "1.8",
              mt: 2,
            }}
          >
           La mise en place de ces dispositifs permet de relever plusieurs défis majeurs, notamment la gestion de l’afflux des réfugiés et des déplacés aux frontières tchadiennes, conséquences des conflits régionaux, la lutte contre les menaces sécuritaires liées au terrorisme international et aux trafics illicites et la limitation de la prolifération des faux documents et de l’usurpation d’identité.
          </Typography>




          <Typography
            variant="body1"
            sx={{
              fontSize: "18px",
              textAlign: "justify",
              lineHeight: "1.8",
              mt: 2,
            }}
          >
Dans les années à venir, l’ANATS poursuivra sa mission avec détermination. Elle continuera à développer des solutions innovantes et sécurisées, à simplifier les démarches administratives et à améliorer la satisfaction des citoyens. Notre objectif est de devenir un acteur clé de la modernisation de l’administration publique.        
  </Typography>


  <Typography
            variant="body1"
            sx={{
              fontSize: "18px",
              textAlign: "justify",
              lineHeight: "1.8",
              mt: 2,
            }}
          >
Nous vous remercions de votre confiance et vous invitons à parcourir notre site afin de trouver toutes les informations utiles sur nos services, les démarches à suivre et les actualités de l’ANATS.  </Typography>
       
        </Box>
      </Box>
    </Container>
  );
};

export default CEOMessage;
