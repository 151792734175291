export const newsData = [
  {
    id: 1,
    title: "Formation de renforcement des capacités des opérateurs du CAU de Farcha",
    date: "6 février 2025",
    place: "Centre de Farcha, 1er Arr",
    description:
      "Dans le cadre du renforcement des compétences du personnel, l’Agence Nationale des Titres Sécurisés (ANATS) a organisé une session de formation destinée aux opérateurs du Centre d’Accueil des Usagers de Farcha. Cette formation s’est tenue dans les locaux dudit centre et a été officiellement lancée par Mme WAFA KABORO HISSEIN, Directrice de la Formation, de la Communication et de la Sensibilisation, en présence de M. MAHAMAT ABDRAMAN KOTY, Directeur des Centres d’Accueil des Usagers, et de M. MAHAMAT MBODOU YAYA, Directeur du Personnel.\n\nL’objectif de cette session est de permettre aux agents d’améliorer la qualité du service rendu aux usagers et de renforcer leurs compétences techniques dans la gestion des titres sécurisés. Deux thèmes principaux ont été abordés :\n- L’accueil des usagers, afin d’optimiser l’expérience des citoyens dans les centres d’accueil ;\n- La saisie des informations biographiques et le recueil des données biométriques, pour assurer une meilleure gestion des dossiers et garantir la fiabilité des informations enregistrées.\n\nCette initiative s’inscrit dans la politique de formation continue de l’ANATS, visant à professionnaliser davantage les agents en charge des opérations d’identification et de délivrance des titres sécurisés.",
    images: ["/images/news/Picture1.png", "/images/news/Picture2.png", "/images/news/Picture3.png"]
  },
  {
    id: 2,
    title: "Renforcement des capacités des agents des  CAUs de Champ de Fil et Klemat",
    date: "12 février 2025",
    place: "Centre Champ de Fil",
    description:
      "Dans le cadre de son engagement à améliorer la qualité des services fournis aux citoyens, l’Agence Nationale des Titres Sécurisés (ANATS), à travers sa Direction de la Formation, de la Communication et de la Sensibilisation, a organisé une session de formation au profit des agents du Centre d’Accueil des Usagers de Champ de Fil. Cette session, tenue le 12 février 2025, fait suite à celle réalisée le 7 février au Centre d’Accueil des Usagers de Klemat. Il s’agit de la troisième session dans le cadre d’un plan global de renforcement des capacités couvrant progressivement tous les centres d’accueil de la capitale.\n\nL’objectif de cette formation est d’améliorer les compétences des agents dans l’accueil et l’orientation des usagers, la saisie et la vérification des informations biographiques, ainsi que la gestion des données biométriques. Ces formations visent également à renforcer leur capacité à traiter efficacement les demandes de titres sécurisés et à réduire les délais d’attente pour les citoyens.\n\nLes sessions ont enregistré une participation active des agents ainsi que de l’ensemble des directeurs concernés. L’ouverture de la formation a été marquée par des interventions des responsables de l’ANATS, mettant en avant l’importance du renforcement des capacités du personnel pour garantir un service public de qualité.\n\nÀ travers ces formations continues, l’ANATS réaffirme son engagement à offrir des services efficaces et accessibles à tous, en dotant ses agents des outils nécessaires pour répondre aux attentes des citoyens avec professionnalisme et rigueur.",
    images: ["/images/news/Picture4.png", "/images/news/Picture2.png", "/images/news/Picture3.png"]
  },


  {
    id: 3,
    title: "Lancement de l’opération d’enrôlement des réfugiés pour la délivrance des documents ",
    date: "25 février 2025",
    place: "Bureau du HCR, Tchad",
    description:
    "Dans le cadre de la protection et de l’inclusion administrative des réfugiés au Tchad, l’Agence Nationale des Titres Sécurisés (ANATS), en collaboration avec ses partenaires, a procédé ce mardi 25 février 2025 au lancement officiel de l’opération d’enrôlement des réfugiés pour la délivrance de documents sécurisés.\n\n Cette initiative, placée sous le Haut Patronage du Ministre de la Sécurité Publique et de l’Immigration, M. Ali Ahmat Aghabach, vise à doter les réfugiés de pièces d’identité sécurisées leur permettant d’accéder plus facilement aux services de base et de garantir leurs droits fondamentaux. \n\nLa cérémonie d’ouverture, tenue au bureau du Haut Commissariat pour les Réfugiés (HCR), a réuni plusieurs personnalités de haut niveau, parmi lesquelles des responsables de missions diplomatiques, des représentants du système des Nations Unies au Tchad et des membres de différents ministères impliqués dans cette initiative. À travers cette opération, l’ANATS et ses partenaires réaffirment leur engagement à accompagner le gouvernement tchadien dans ses efforts d’inclusion et de sécurisation des populations réfugiées.\n\n Ce programme permettra non seulement d’assurer une meilleure identification des réfugiés, mais aussi de renforcer la gestion des flux migratoires et de faciliter leur accès aux opportunités socio-économiques. \n\nLe processus d’enrôlement, qui se déroulera en plusieurs étapes, inclura la collecte des données biographiques et biométriques des réfugiés, suivie de la délivrance des documents sécurisés conformes aux normes internationales. \n\nCette initiative marque une avancée significative dans la reconnaissance et la protection des droits des réfugiés au Tchad.",
     images: ["/images/news/Picture31.png", "/images/news/Picture32.png", "/images/news/Picture33.png"]
  },

  {
    id: 4,
    title: "Passation de service entre les directeurs de l’ANATS ",
    date: "31 décembre 2024",
    place: "Siège de l’ANATS",
    description:
   "L’Agence Nationale des Titres Sécurisés (ANATS) a organisé, ce mardi 31 décembre 2024, la cérémonie officielle de passation de service entre les directeurs entrants, nommés par le décret N°2009/PR/PM/MSPI/2024, et leurs prédécesseurs. \n\n Présidée par le Président du Conseil d’Administration, M. Adoum Hassan Arsin, la cérémonie s’est déroulée en présence du Directeur Général de l’ANATS, M. Mahamat Oumar Kessou, ainsi que de plusieurs responsables de l’agence. Cet événement marque un moment clé dans la continuité institutionnelle et le renforcement de l’administration de l’ANATS.",

     images: ["/images/news/Picture41.png", "/images/news/Picture42.png", "/images/news/Picture43.png"]
  },

  {
    id: 5,
    title: "Rencontre de prise de contact entre le Ministre de la MSPI l’ANATS ",
    date: "29 octobre 2024",
    place: "MSPI",
    description:
"Ce mardi 29 octobre 2024, M. Ali Ahamat Akabache, Ministre de la Sécurité Publique et de l’Immigration, a tenu une réunion de prise de contact avec les responsables de l’Agence Nationale des Titres Sécurisés (ANATS). Il était accompagné du Secrétaire Général, de l’Inspecteur Général, de son Directeur de Cabinet et de ses conseillers.\n\nCette rencontre visait à établir un premier échange avec l’équipe dirigeante de l’ANATS et à définir de nouvelles orientations stratégiques pour l’institution.\n\n À cette occasion, le Directeur Général de l’ANATS, M. Mahamat Oumar Kessou, a présenté un exposé détaillant le contexte de création de l’agence, son cadre juridique, ses objectifs et missions, ainsi que le processus de traitement des dossiers pour l’ obtention du Numéro National d’Identification (NNI). Il a également mis en lumière les défis majeurs auxquels l’ANATS est confrontée.\n\n Le Directeur Général était accompagné de M. Hassan Adoum Arsin, Président du Conseil d’Administration, ainsi que des directeurs techniques, des coordinateurs, de l’Inspecteur Auditeur Interne et de l’Agent Comptable.\n\n",
     images: ["/images/news/Picture51.png", "/images/news/Picture52.png", "/images/news/Picture53.png"]
  },

  

  {
    id: 7,
    title: "L’ANATS, Sponsor du Forum National sur l’Emploi",
    date: "17 octobre 2024",
    place: "N’Djamena",
    description:
"L’Agence Nationale des Titres Sécurisés (ANATS), en tant que sponsor du Forum National sur l’Emploi, a eu l’honneur d’accueillir à son stand Son Excellence MAHAMAT IDRISS DEBY ITNO, Président de la République, Chef de l’État.\n\n Cette visite a été une occasion unique pour l’ANATS de présenter les opportunités qu’elle offre aux demandeurs d’emploi et de mettre en avant son engagement dans les initiatives gouvernementales visant à favoriser l’insertion professionnelle des jeunes.\n\n L’ANATS remercie chaleureusement tous les visiteurs de son stand et réaffirme son engagement à œuvrer pour le service des citoyens.\n\n",
    images: ["/images/news/Picture11.png", "/images/news/Picture12.png", "/images/news/Picture13.png"]
  },



  {
    id: 8,
    title: "L’ANATS à la Conférence : Journées du Chargeur Tchadien",
    date: "11 au 13 juillet 2024",
    place: " Radisson Blu",
    description:
    "L’Agence Nationale des Titres Sécurisés (ANATS), sponsor des Journées du Chargeur Tchadien, a activement participé à cet événement organisé par le Conseil des Chargeurs Tchadiens sous le Haut Patronage de Son Excellence MAHAMAT IDRISS DEBY ITNO, Président de la République, Chef de l’État.\n\n L’ANATS a marqué sa présence à travers un stand d’exposition et l’installation d’un centre mobile, permettant aux visiteurs de suivre l’état de leurs titres sécurisés (cartes d’identité, passeports, cartes grises, permis de conduire, cartes de résident). Plusieurs actes de naissance sécurisés ont été délivrés gratuitement à des centaines de citoyens.\n\n Lors des conférences-débats, l’ANATS a présenté aux chargeurs internationaux les procédures simplifiées pour l’obtention des titres de séjour pour les étrangers, des permis de conduire pour les chauffeurs et des cartes grises pour les poids lourds. Elle a également mis en avant ses capacités à développer des solutions numériques pour les administrations publiques et privées.\n\n Impressionnés par les services de l’ANATS, plusieurs participants africains ont exprimé leur intérêt pour une coopération bilatérale en vue de moderniser leurs systèmes d’identification.\n\n Les journées se sont clôturées par un dîner de gala offert aux sponsors, avec un rendez-vous fixé pour la deuxième édition en mai 2025.\n\n",
        images: ["/images/news/Picture91.png", "/images/news/Picture92.png", "/images/news/Picture93.png"]
  },
  {
    id: 9,
    title: " ANATS au service des citoyens nomades",
    date: "24 jan 2025",
    place: "d’Albiher/Wadi Fira",
    description:
"Dans le cadre de la facilitation de l’accès aux documents d’état civil pour les citoyens nomades, 2165 pasteurs et agropasteurs, dont un nombre important de femmes, ont reçu des cartes d’identité nationales ainsi que des actes de naissance pour leurs enfants. La remise de ces documents a eu lieu lors d’une cérémonie officielle organisée dans la sous-préfecture de Kadjmar, en présence du Ministre de l’Élevage et des Productions Animales, M. Abderahim Awat Atteib.\n\n Cette initiative s’inscrit dans une dynamique d’inclusion et de reconnaissance des droits des communautés nomades, souvent confrontées à des difficultés d’accès aux services administratifs. Grâce à ces documents, ces citoyens pourront désormais bénéficier de services sociaux de base et exercer pleinement leurs droits.\n\n L’Agence Nationale des Titres Sécurisés (ANATS) félicite le Ministère en charge de l’Élevage pour cette initiative et exprime sa reconnaissance envers les partenaires techniques et financiers, notamment le Projet PRAPS 2, qui ont soutenu cette action en faveur de cette catégorie de population vulnérable. Cette opération marque une avancée significative dans la lutte contre l’apatridie et la promotion de l’identité légale pour tous.\n\n",       
 images: ["/images/news/Picture111.png", "/images/news/Picture112.png", "/images/news/Picture113.png"]
  
}
];
