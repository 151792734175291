// src/App.js
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./components/Header";
import Home from "./pages/Home";
import Contact from "./components/Contact/Contact";
import News from "./components/News/News";
import Map from "./components/Map";
import Footer from "./components/Footer";
import Organigram from './components/Organigram/Organigram'
import CitizenStepper from './components/CitizenStepper'
import ResidentStepper from './components/CitizenStepper'
import ImmatriculationStepper from './components/ImmatriculationStepper'
import Partners from "./components/Partners/Partners";
import Presentation from "./components/Presentation/Presentation";
import ReclamationPage from "./components/Presentation/ReclamationPage";
import ScrollToTop from "./components/ScrollToTop"; 
import Documents from "./components/Documents"; 
import PresentationPage from './components/Presentation/PresentationPage'
import CEOMessage from './components/CEOMessage'
import EtatCivilStepper from './components/etatCivilStepper'
import Breadcrumbs from "./components/BreadCrumb/Breadcrumbs";
import DownloadableForms from './components/DownloadableForms'
import Gallery from './components/Gallery/Gallery'
import FAQ from './components/FAQ'

import './App.css'
import NewsDetail from "./components/News/NewsDetails";

const App = () => {
  return (
    <Router>

<div className="app-container">

      <Header />
      <Breadcrumbs /> 
      <ScrollToTop />
      <div className="content">

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/Actualites" element={<News />} />
        <Route path="/map" element={<Map />} />
        <Route path="/map" element={<Partners />} />
        <Route path="/Organigramme" element={<Organigram />} />
        {/* <Route path="/citizenStepper/"   element={<CitizenStepper />} /> */}
        <Route path="/citoyen/:category" element={<CitizenStepper />} />
        {/* <Route path="/citoyen/:category" element={<CitizenStepper />} /> */}

        {/* <Route path="/residentStepper" element={<ResidentStepper />} /> */}
        {/* <Route path="/ImmatriculationStepper" element={<ImmatriculationStepper />} /> */}

        <Route path="/Etat-Civil" element={<EtatCivilStepper />} />

        <Route path="/Presentation" element={<Presentation />} />
        <Route path="/Reclamation" element={<ReclamationPage />} />
        <Route path="/documents" element={<Documents documentType = "Décret" />} />
        <Route path="/loi" element={<Documents documentType = "loi" />} />
        <Route path="/Ordonnance" element={<Documents documentType = "ordonnance" />} />
        <Route path="/report" element={<Documents documentType = "report" />} />
        <Route path="/PresentationPage" element={<PresentationPage />} />
        <Route path="/mot-du-dg" element={<CEOMessage />} />
        <Route path="/Formulaires" element={<DownloadableForms />} />

                 <Route path="/Galerie" element={<Gallery />} />
                 <Route path="/FAQ" element={<FAQ />} />


                 <Route path="/Actualites/:id" element={<NewsDetail />} />

        </Routes>
        </div>

       <Footer />
       </div>

    </Router>
  );
};

export default App;
