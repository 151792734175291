import React from "react";
import {
  Box,
  Container,
  Typography,
  Paper,
  Grid,
   
} from "@mui/material";
import { motion } from "framer-motion";
 import SecurityIcon from "@mui/icons-material/Security";
import UpdateIcon from "@mui/icons-material/Update";
import FingerprintIcon from "@mui/icons-material/Fingerprint";
import StorageIcon from "@mui/icons-material/Storage";
import ComputerIcon from "@mui/icons-material/Computer";
import EngineeringIcon from "@mui/icons-material/Engineering";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import PublicIcon from "@mui/icons-material/Public";
 
 import MissionList from './MissionList';
import SectionTitle from "../SectionTitle";
const missions = [
  {
    icon: <SecurityIcon fontSize="large" sx={{ color: "#1D3557" }} />,
    text: "Assurer l'enregistrement et la mise à jour des informations d'identification.",
  },
  {
    icon: <UpdateIcon fontSize="large" sx={{ color: "#1D3557" }} />,
    text: "Mettre en œuvre un système d'information fiable et sécurisés.",
  },
  {
    icon: <FingerprintIcon fontSize="large" sx={{ color: "#1D3557" }} />,
    text: 'Personnaliser et produire les Titres sécurisés   .',
  },
  {
    icon: <StorageIcon fontSize="large" sx={{ color: "#1D3557" }} />,
    text: "Mettre en place une infrastructure cryptographique.",
  },
  {
    icon: <ComputerIcon fontSize="large" sx={{ color: "#1D3557" }} />,
    text: "Assurer le développement et la maintenance des systèmes informatiques.",
  },
  {
    icon: <EngineeringIcon fontSize="large" sx={{ color: "#1D3557" }} />,
    text: "Définir les normes techniques et veiller à leur application.",
  },
  {
    icon: <SupervisorAccountIcon fontSize="large" sx={{ color: "#1D3557" }} />,
    text: "Superviser les Centres d'Accueil des usagers.",
  },
  {
    icon: <LibraryBooksIcon fontSize="large" sx={{ color: "#1D3557" }} />,
    text: "Organiser les registres et archives de l'état civil.",
  },
  {
    icon: <PublicIcon fontSize="large" sx={{ color: "#1D3557" }} />,
    text: "Promouvoir les technologies nationales en matière de Titres sécurisés.",
  },
];

 

const Presentation = () => {
  return (
    <Box sx={{ backgroundColor: "#f8f9fa", paddingTop: 6, paddingBottom: 6 }}>
      <Container maxWidth="lg">
         <Grid container spacing={6} sx={{ marginBottom: 8 }}>
          <Grid item xs={12} md={12}>
            <Typography
              variant="h4"
              sx={{
                color: "#2c3e50",
                fontWeight: "bold",
                marginBottom: 3,
                fontSize: "1.8rem",
                lineHeight: 1.3,
              }}
            >
           <SectionTitle text="Présentation"/>   
            </Typography>
            <Typography
              variant="body1"
              sx={{
                color: "#555",
                lineHeight: 1.8,
                fontSize: "1rem",
                textAlign: "justify",
              }}
            >
               Il est créé en République du Tchad, une <b> Agence Nationale des Titres Sécurisés (ANATS) </b>. L’ANATS est un établissement Public, doté de la personnalité morale et de l’autonomie de gestion.
L’ANATS est placée sous la tutelle de Ministère en charge de la Sécurité.Son siège est fixé à Ndjamena.Elle peut avoir des organes régionaux.

  <br/>Elle est responsable de la production, de la gestion et de la
              sécurisation des documents officiels tels que les passeports, les cartes d'identité, et autres titres
              sécurisés. Notre mission principale est d'assurer la sécurité et l'intégrité des données personnelles
              des citoyens tout en offrir des services efficaces et modernes pour l'obtention de ces documents.
            </Typography>
          </Grid>
      
        </Grid>

         <Box sx={{ marginBottom: 8 }}>
          <Typography
            variant="h5"
            sx={{
              color: "#1D3557",
              marginBottom: 4,
              fontWeight: "bold",
              fontSize: "1.5rem",
              textAlign: "center",
            }}
          >
           Objectifs de l'ANATS
          </Typography>
          <Grid container spacing={4}>
            {missions.map((mission, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <motion.div
          
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0 }}  
                viewport={{ once: true }}
              >
                 
                
                  <Paper
                    sx={{
                      marginBottom:3,
                      padding: 3,
                      backgroundColor: "#fff",
                      borderRadius: 2,
                      textAlign: "center",
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
                      "&:hover": {
                        boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
                      },
                    }}
                  >
                    <Box sx={{ marginBottom: 2 }}>{mission.icon}</Box>
                    <Typography
                      variant="body1"
                      sx={{
                        color: "#555",
                        fontSize: "0.95rem",
                        textAlign: "center",
                      }}
                    >
                      {mission.text}
                    </Typography>
                  </Paper>
                </motion.div>
                
              </Grid>
            ))}
          </Grid>
        </Box>
<MissionList/>
      </Container>
    </Box>
  );
};

export default Presentation;