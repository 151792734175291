import React from 'react';
import { Tree, TreeNode } from 'react-organizational-chart';
import './OrgChartPageStyle.css';
import Typography from '@mui/material/Typography'
import SectionTitle from '../SectionTitle'
import { useMediaQuery } from '@mui/material';

 function Organization({ name, title, email, image }) {
  return (
    <div className="org-node-container" style={{backgroundColor:'red'}}>
      {/* {image && (
        <div className="org-photo">
          <img src={image} alt={name} />
        </div>
      )} */}
      <div className="org-title">{name}</div>
      {title && <div className="org-manager">{title}</div>}
      {/* {email && <div className="org-email"> {email}</div>} */}
    </div>
  );
}

 function Node({ data, parent }) {
  const TreeComponent = parent ? TreeNode : Tree;

  return (
    <TreeComponent
      className='m-5'
      lineWidth={'2px'}
      lineColor={'#0E4387'}
      lineBorderRadius={'10px'}
      label={<Organization {...data} />}
    >
      {data.children && data.children.map((child) => (
        <Node key={child.id} data={child} parent={data} />
      ))}
    </TreeComponent>
  );
}

 const orgData = {
  id: 1,
  name: 'Conseil d’Administration',
  title: 'Mahamat Adam Arsine (PCA) ',
  // email: 'mahamat.ali@anats.td',
  children: [
   { id: 2,
    name: 'Direction générale ',
    title: 'Mahamat Oumar Kessou (DG)',
    // email: 'okesso@anats.td',
    children:[ {
      id: 2,
      name: 'Direction générale ',
      title: 'Youssouf Gourou Tchabi (DGA)',

      // email: 'okesso@anats.td',
      children: [
        
        {
          id: 4,
          name: 'Direction des Affaires Juridiques (DAJ)',
          title: 'Gal. Ismail Moussa Fadoul Sawa',
          // email: 'ismail.fadoul@anats.td',
          children: [],
        },
        {
          id: 5,
          name: 'Direction des centres d’accueil des usages (DCAU))',
          title: 'Mahamat Koty Abdrahman',
          // email: 'dcau@anats.td',
          children: [],
        },
        {
          id: 6,
          name: "Direction de la System  integres securise (DSIS)",
          title: 'Mahmoud Dackou Abderaman (Directeur)',
          // email: 'dackou@anats.td',
          children: [],
        },
        {
          id: 7,
          name: 'Agent Comptable',
          title: 'Mahamat Rozi (Comptable)',
          // email: 'rozi@anats.td',
          children: [],
        },
        {
          id: 8,
          name: 'Direction de la formation de la communication et de sensibilisation (DFCS)',
          title: 'Mme. Wafa Kabaro Hisseine',
          // email: 'hassan.bolobo@example.com',
          children: [],
        },
        {
          id: 9,
          name: 'Direction des affaires administrative materielle et personnel (DAAMP)',
          title: 'Mahamat Mbodou Yaya',
          // email: 'ali.mht@anats.td',
          children: [],
        },

        {
          id: 10,
          name: 'Coordination des actions de police',
          title: 'Acyle',
          // email: 'ali.mht@anats.td',
          children: [],
        },

        {
          id: 11,
          name: 'Coordination etat civil',
          title: 'Brahim Anama',
          // email: 'ali.mht@anats.td',
          children: [],
        },

        {
          id: 12,
          name: 'Coordination d’enrolement mobile',
          title: 'Zakaria Salim',
          // email: 'ali.mht@anats.td',
          children: [],
        },

        {
          id: 13,
          name: 'Inspection d’audit interne',
          title: 'Abakar Segue ',
          // email: 'ali.mht@anats.td',
          children: [],
        },


        

           ],
     
    },]
   },
   
  ],
};

 export default function OrgChartPage() {
  const isTabletOrMobile = useMediaQuery('(max-width: 960px)');  
  return (
    <section className="">
      <div className="p-5">
        <div style={{  padding: '20px',textAlign: "center", mt: 4,mb:2 }} className="row text-center">       
          <Typography   variant="h4" gutterBottom sx={{ fontWeight: "bold", mb: 1 }}>
       
          <SectionTitle text=" Organigramme de l'ANATS" />

                  
          </Typography>
        </div>

        <div  className="row text-center orgchart-wrapper">
          <div className="orgchart-container">
            {isTabletOrMobile ? (
              
              <img src="/images/org.png" alt="Organigramme"
              
              style={{ width: '100%', maxWidth: '600px', margin: 'auto' }}

               />
            ) : (
              <Node  data={orgData} />
            )}
          </div>
        </div>
      </div>
    </section>
  );
}



// import React from 'react';
// import { Tree, TreeNode } from 'react-organizational-chart';
// import './OrgChartPageStyle.css';
// import Typography from '@mui/material/Typography'
// import SectionTitle from '../SectionTitle'
// import { useMediaQuery } from '@mui/material';

//  function Organization({ name, title, email, image }) {
//   return (
//     <div className="org-node-container" style={{backgroundColor:'red'}}>
//       {/* {image && (
//         <div className="org-photo">
//           <img src={image} alt={name} />
//         </div>
//       )} */}
//       <div className="org-title">{name}</div>
//       {title && <div className="org-manager">{title}</div>}
//       {/* {email && <div className="org-email"> {email}</div>} */}
//     </div>
//   );
// }

//  function Node({ data, parent }) {
//   const TreeComponent = parent ? TreeNode : Tree;

//   return (
//     <TreeComponent
//       className='m-5'
//       lineWidth={'2px'}
//       lineColor={'#0E4387'}
//       lineBorderRadius={'10px'}
//       label={<Organization {...data} />}
//     >
//       {data.children && data.children.map((child) => (
//         <Node key={child.id} data={child} parent={data} />
//       ))}
//     </TreeComponent>
//   );
// }

//  const orgData = {
//   id: 1,
//   name: 'Conseil d’Administration',
//   title: 'Mahamat Adam Arsine (PCA) ',
//   // email: 'mahamat.ali@anats.td',
//   children: [
//    { id: 2,
//     name: 'Direction générale ',
//     title: 'Mahamat Oumar Kessou (DG)',
//     // email: 'okesso@anats.td',
//     children:[ {
//       id: 2,
//       name: 'Direction générale (DGA)',
//       title: 'Youssouf Gourou Tchabi (DGA)',

//       // email: 'okesso@anats.td',
//       children :[{
//         id: 20,
//       name: 'Direction Techniques',
//       // title: 'Youssouf Gourou Tchabi (DGA)',
//         children: [
        
//           {
//             id: 4,
//             name: 'Direction des Affaires Juridiques (DAJ)',
//             title: 'Gal. Ismail Moussa Fadoul Sawa',
//             // email: 'ismail.fadoul@anats.td',
//             children: [],
//           },
//           {
//             id: 5,
//             name: 'Direction des centres d’accueil des usages (DCAU))',
//             title: 'Mahamat Koty Abdrahman',
//             // email: 'dcau@anats.td',
//             children: [],
//           },
//           {
//             id: 6,
//             name: "Direction de la System  integres securise (DSIS)",
//             title: 'Mahmoud Dackou Abderaman (Directeur)',
//             // email: 'dackou@anats.td',
//             children: [],
//           },
          
//           {
//             id: 8,
//             name: 'Direction de la formation de la communication et de sensibilisation (DFCS)',
//             title: 'Mme. Wafa Kabaro Hisseine',
//             // email: 'hassan.bolobo@example.com',
//             children: [],
//           },
//           {
//             id: 9,
//             name: 'Direction des affaires administrative materielle et personnel (DAAMP)',
//             title: 'Mahamat Mbodou Yaya',
//             // email: 'ali.mht@anats.td',
//             children: [],
//           },
  
          
          
  
  
          
  
//              ],
//       },{
//         id: 20,
//         name: 'Cordinations',
//         children:[{
//           id: 10,
//           name: 'Coordination des actions de police',
//           title: 'Acyle',
//           // email: 'ali.mht@anats.td',
//           children: [],
//         },

//         {
//           id: 11,
//           name: 'Coordination etat civil',
//           title: 'Brahim Anama',
//           // email: 'ali.mht@anats.td',
//           children: [],
//         },

//         {
//           id: 12,
//           name: 'Coordination d’enrolement mobile',
//           title: 'Zakaria Salim',
//           // email: 'ali.mht@anats.td',
//           children: [],
//         },

          
//         ]
//       },{

     
        
//           id: 13,
//           name: 'Inspection',
//           // title: 'Abakar Segue ',
//           // email: 'ali.mht@anats.td',
//           children: [
//             {id: 111,
//             name: 'Inspection d’audit interne',
//             title: 'Abakar Segue ',
//             // email: 'ali.mht@anats.td',
//             }
//           ],
        
//       },{

        
//           id: 23,
//           name: ' Comptabilite',
//           // title: 'Mahamat Rozi (Comptable)',
//           // email: 'rozi@anats.td',
//           children: [{
//             name: ' Comptabilite',

//             title: 'Mahamat Rozi (Comptable)',
//           // email: 'rozi@anats.td',
//           }],
        

//       }]
     
//     },]
//    },
   
//   ],
// };

//  export default function OrgChartPage() {
//   const isTabletOrMobile = useMediaQuery('(max-width: 960px)');  
//   return (
//     <section className="">
//       <div className="p-5">
//         <div style={{  padding: '20px',textAlign: "center", mt: 4,mb:2 }} className="row text-center">       
//           <Typography   variant="h4" gutterBottom sx={{ fontWeight: "bold", mb: 1 }}>
       
//           <SectionTitle text=" Organigramme de l'ANATS" />

                  
//           </Typography>
//         </div>

//         <div  className="row text-center orgchart-wrapper">
//           <div className="orgchart-container">
//             {isTabletOrMobile ? (
              
//               <img src="/images/org.png" alt="Organigramme"
              
//               style={{ width: '100%', maxWidth: '600px', margin: 'auto' }}

//                />
//             ) : (
//               <Node  data={orgData} />
//             )}
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// }

