import React, { useState } from "react";
import {
  Container,
  Typography,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  Paper,
} from "@mui/material";
import { Send } from "@mui/icons-material";
import SectionTitle from "../SectionTitle";
import { useNavigate } from "react-router-dom";
const ReclamationPage = () => {

  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/Mot-du-DG"); // Redirect to specified route
    window.scrollTo({ top: 0, behavior: "smooth" }); // Ensure smooth scroll to top
  };
  const [typeReclamation, setTypeReclamation] = useState("");
  const [centreAccueil, setCentreAccueil] = useState("");
  const [details, setDetails] = useState("");
  const [submitted, setSubmitted] = useState(false);

   const centresAccueil = [
    "Centre d'Accueil de Farcha (N'Djamena)",
    "Centre d'Accueil de Goudji (N'Djamena)",
    "Centre d'Accueil de Diguel (N'Djamena)",
    "Centre d'Accueil de Chagoua (N'Djamena)",
    "Centre d'Accueil de Klemat (N'Djamena)",
    "Centre d'Accueil de Dembé (N'Djamena)",
    "Centre d'Accueil de Moussoro (Moussoro)",
    "Centre d'Accueil de Moundou (Moundou)",
    "Centre d'Accueil de Faya (Faya)",
    
    
  ];

  const handleTypeChange = (event) => {
    setTypeReclamation(event.target.value);
  };

  const handleCentreChange = (event) => {
    setCentreAccueil(event.target.value);
  };

  const handleDetailsChange = (event) => {
    setDetails(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Simuler l'envoi du formulaire
    console.log("Type de réclamation :", typeReclamation);
    console.log("Centre d'accueil concerné :", centreAccueil);
    console.log("Détails :", details);
    setSubmitted(true);
    setTimeout(() => {
      setSubmitted(false);
      setTypeReclamation("");
      setCentreAccueil("");
      setDetails("");
    }, 3000);
  };

  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Paper
        sx={{
          p: 4,
          borderRadius: 2,
          boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
          backgroundColor: "#fff",
        }}
      >
        <Typography
          variant="h4"
          gutterBottom
          textAlign="center"
          sx={{ fontWeight: "bold", color: "#1D3557", mb: 3 }}
        >
       <SectionTitle text=" Formulaire de Réclamation" />  
        </Typography>
        <Typography
          variant="body1"
          color="textSecondary"
          textAlign="center"
          sx={{ mb: 4 }}
        >
          Veuillez remplir ce formulaire pour soumettre une réclamation ou un signalement.
        </Typography>

        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{ display: "flex", flexDirection: "column", gap: 3 }}
        >
           <FormControl fullWidth>
            <InputLabel id="type-reclamation-label">Type de réclamation</InputLabel>
            <Select
              labelId="type-reclamation-label"
              id="type-reclamation"
              value={typeReclamation}
              label="Type de réclamation"
              onChange={handleTypeChange}
              required
            >
              <MenuItem value="Fraude">Fraude</MenuItem>
              <MenuItem value="Corruption">Corruption</MenuItem>
              <MenuItem value="Autre">Autre</MenuItem>
            </Select>
          </FormControl>

           <FormControl fullWidth>
            <InputLabel id="centre-accueil-label">Centre d'accueil concerné</InputLabel>
            <Select
              labelId="centre-accueil-label"
              id="centre-accueil"
              value={centreAccueil}
              label="Centre d'accueil concerné"
              onChange={handleCentreChange}
              required
            >
              {centresAccueil.map((centre, index) => (
                <MenuItem key={index} value={centre}>
                  {centre}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

           <TextField
            fullWidth
            label="Détails de la réclamation"
            multiline
            rows={6}
            value={details}
            onChange={handleDetailsChange}
            required
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: 2,
              },
            }}
          />

           {submitted ? (
            <Typography
              variant="h6"
              color="success.main"
              textAlign="center"
              sx={{ fontWeight: "bold" }}
            >
              Votre réclamation a été envoyée avec succès !
            </Typography>
          ) : (
            // <Button
            //   type="submit"
            //   variant="contained"
            //   color="primary"
            //   startIcon={<Send />}
            //   sx={{
            //     mt: 2,
            //     alignSelf: "center",
            //     borderRadius: 2,
            //     padding: "10px 24px",
            //     fontWeight: "bold",
            //     backgroundColor: "#1D3557",
            //     "&:hover": {
            //       backgroundColor: "#14213D",
            //     },
            //   }}
            // >
            //   Envoyer
            // </Button>


         <Button
      onClick={handleClick}
      variant="contained"
      sx={{
        background: "linear-gradient(45deg, #1e88e5, #42a5f5)",
        color: "white",
        fontSize: "18px",
        fontWeight: "bold",
        textTransform: "none",
        borderRadius: "10px",
        px: 4,
        py: 1.5,
        boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.3)",
        transition: "all 0.3s ease-in-out",
        "&:hover": {
          background: "linear-gradient(45deg, #1565c0, #1e88e5)",
          transform: "scale(1.05)",
        },
      }}
    >
              Envoyer
              </Button>
          )}
        </Box>
      </Paper>
    </Container>
  );
};

export default ReclamationPage;