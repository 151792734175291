import React, { useState } from "react";
import {
  Container,
  TextField,
  Button,
  Typography,
  Box,
  Link,
  Grid,
  Avatar,
  Paper,
  Card,
} from "@mui/material";
import {
  Facebook,
  Twitter,
  Email,
  LocationOn,
  AccessTime,
  Phone,
  Person,
  Mail,
  Message,
} from "@mui/icons-material";
import { motion } from "framer-motion"; // Pour les animations
import SectionTitle from "../SectionTitle";
import { useNavigate } from "react-router-dom";
const ContactPage = () => {


  const navigate = useNavigate();

  const handleClick = () => {
    // navigate("/"); // Redirect to specified route
    // window.scrollTo({ top: 0, behavior: "smooth" }); // Ensure smooth scroll to top
  };

  const [formData, setFormData] = useState({ name: "", email: "", message: "" });
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState("");

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!formData.name || !formData.email || !formData.message) {
      setError("Veuillez remplir tous les champs.");
      return;
    }
    if (!/\S+@\S+\.\S+/.test(formData.email)) {
      setError("Veuillez entrer une adresse email valide.");
      return;
    }
    setError("");
    setSubmitted(true);
    // Simuler l'envoi du formulaire
    setTimeout(() => {
      setFormData({ name: "", email: "", message: "" });
      setSubmitted(false);
    }, 3000);
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Card
        sx={{
          borderRadius: 4,
          boxShadow: "0 8px 32px rgba(0, 0, 0, 0.1)",
          overflow: "hidden",
          p: 4,
          backgroundColor: "#ffffff",
        }}
        component={motion.div}
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
      >
        <Typography
          variant="h4"
          gutterBottom
          textAlign="center"
          fontWeight="bold"
          sx={{ color: "#1D3557", mb: 3 }}
        >
      <SectionTitle  text="Contactez-nous"/>    
        </Typography>
        <Typography
          variant="body1"
          color="textSecondary"
          textAlign="center"
          sx={{ mb: 4 }}
        >
          Pour toute demande, remplissez ce formulaire ou contactez-nous à
          <Link
            href="mailto:contact@anats.td"
            sx={{ ml: 1, color: "#1D3557", fontWeight: "bold" }}
          >
            contact@anats.td
          </Link>
        </Typography>

        <Grid container spacing={4} justifyContent="center">
          {/* Formulaire */}
          <Grid item xs={12} md={6}>
            <Paper
              sx={{
                p: 3,
                borderRadius: 3,
                boxShadow: "0 4px 16px rgba(0, 0, 0, 0.1)",
                backgroundColor: "#f8f9fa",
              }}
              component={motion.div}
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.6, delay: 0.2 }}
            >
              {submitted ? (
                <Typography
                  variant="h6"
                  color="success.main"
                  textAlign="center"
                  sx={{ fontWeight: "bold" }}
                >
                  Votre message a été envoyé avec succès !
                </Typography>
              ) : (
                <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
                  {/* Champ Nom */}
                  <Grid container alignItems="center" spacing={2} sx={{ mb: 2 }}>
                    <Grid item>
                      <Person sx={{ color: "#1D3557" }} />
                    </Grid>
                    <Grid item xs>
                      <TextField
                        fullWidth
                        label="Nom"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            borderRadius: 2,
                          },
                        }}
                      />
                    </Grid>
                  </Grid>

                  {/* Champ Email */}
                  <Grid container alignItems="center" spacing={2} sx={{ mb: 2 }}>
                    <Grid item>
                      <Mail sx={{ color: "#1D3557" }} />
                    </Grid>
                    <Grid item xs>
                      <TextField
                        fullWidth
                        label="Email"
                        name="email"
                        type="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            borderRadius: 2,
                          },
                        }}
                      />
                    </Grid>
                  </Grid>

                  {/* Champ Message */}
                  <Grid container alignItems="flex-start" spacing={2} sx={{ mb: 2 }}>
                    <Grid item>
                      <Message sx={{ color: "#1D3557", mt: 1 }} />
                    </Grid>
                    <Grid item xs>
                      <TextField
                        fullWidth
                        label="Message"
                        name="message"
                        multiline
                        rows={4}
                        value={formData.message}
                        onChange={handleChange}
                        required
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            borderRadius: 2,
                          },
                        }}
                      />
                    </Grid>
                  </Grid>

                  {/* Bouton Envoyer */}
                  {error && (
                    <Typography color="error" sx={{ mt: 1, textAlign: "center" }}>
                      {error}
                    </Typography>
                  )}
                 <Button
                    onClick={handleClick}
                    variant="contained"
                    sx={{
                      background: "linear-gradient(45deg, #1e88e5, #42a5f5)",
                      color: "white",
                      fontSize: "18px",
                      fontWeight: "bold",
                      textTransform: "none",
                      borderRadius: "10px",
                      px: 10,
                      py: 1.5,
                      boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.3)",
                      transition: "all 0.3s ease-in-out",
                      "&:hover": {
                        background: "linear-gradient(45deg, #1565c0, #1e88e5)",
                        transform: "scale(1.05)",
                      },
                    }}
                  >
                            Envoyer
                            </Button>
                </Box>
              )}
            </Paper>
          </Grid>

          {/* Informations de contact */}
          <Grid item xs={12} md={6}>
            <Paper
              sx={{
                p: 3,
                borderRadius: 3,
                boxShadow: "0 4px 16px rgba(0, 0, 0, 0.1)",
                backgroundColor: "#f8f9fa",
              }}
              component={motion.div}
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.6, delay: 0.2 }}
            >
              <Box sx={{ textAlign: "center", mt: 2 }}>
                <Typography
                  variant="h6"
                  sx={{ fontWeight: "bold", color: "#1D3557", mb: 2 }}
                >
                  Agence Nationale des Titres Sécurisés (ANATS)
                </Typography>
                <Avatar
                  alt="ANATS Logo"
                  src="logo1.jpg"
                  variant="square"
                  sx={{ width: 100, height: 100, mx: "auto", mb: 3 }}
                />
              </Box>

              {/* Informations de contact */}
              <Box sx={{ mt: 3 }}>
                <Grid container spacing={2}>
                  {/* Adresse */}
                  <Grid item xs={12} display="flex" alignItems="center">
                    <LocationOn sx={{ color: "#1D3557", mr: 2 }} />
                    <Typography variant="body1" sx={{ color: "#555" }}>
 Avenue du General Charfadine Ahmat, N’djamena
                    </Typography>
                  </Grid>

                  {/* Horaires */}
                  <Grid item xs={12} display="flex" alignItems="center">
                    <AccessTime sx={{ color: "#1D3557", mr: 2 }} />
                    <Typography variant="body1" sx={{ color: "#555" }}>
                      Lundi - Vendredi : 07h30 - 15h30
                    </Typography>
                  </Grid>

                  {/* Téléphone */}
                  <Grid item xs={12} display="flex" alignItems="center">
                    <Phone sx={{ color: "#1D3557", mr: 2 }} />
                    <Typography variant="body1" sx={{ color: "#555" }}>
                      +235 93 51 79 79
                    </Typography>
                  </Grid>
                </Grid>
              </Box>

              {/* Suivez-nous */}
              <Box sx={{ mt: 4, textAlign: "center" }}>
                <Typography
                  variant="h6"
                  sx={{ fontWeight: "bold", color: "#1D3557", mb: 2 }}
                >
                  Suivez-nous
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    gap: 3,
                    mt: 1,
                  }}
                >
                  <Link href="https://www.facebook.com/anatstchad" target="_blank">
                    <Facebook
                    
                      fontSize="large"
                      sx={{ color: "#1D3557", "&:hover": { color: "#14213D" } }}
                    />
                  </Link>
                  <Link href="https://twitter.com/" target="_blank">
                    <Twitter
                      fontSize="large"
                      sx={{ color: "#1D3557", "&:hover": { color: "#14213D" } }}
                    />
                  </Link>
                  <Link href="mailto:contact@anats.td">
                    <Email
                      fontSize="large"
                      sx={{ color: "#1D3557", "&:hover": { color: "#14213D" } }}
                    />
                  </Link>
                </Box>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Card>

      {/* Google Map */}
      <Paper
        sx={{
          mt: 4,
          borderRadius: 3,
          boxShadow: "0 4px 16px rgba(0, 0, 0, 0.1)",
          overflow: "hidden",
        }}
        component={motion.div}
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6, delay: 0.4 }}
      >
        <iframe
          title="Google Maps"
          width="100%"
          height="300"
          frameBorder="0"
          style={{ border: 0, borderRadius: "8px" }}
          loading="lazy"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3900.190950534731!2d15.043580975353288!3d12.167399431998726!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x11196355141c183f%3A0x13c509771ea5453b!2sANATS!5e0!3m2!1sen!2ses!4v1741443226469!5m2!1sen!2ses"          allowFullScreen
        ></iframe>
      </Paper>
    </Container>
  );
};

export default ContactPage;

 