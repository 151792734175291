

import React from "react";
import { Stepper, Step, StepLabel, Typography, Paper, Grid, StepConnector, Box } from "@mui/material";
import { styled } from "@mui/system";
import SectionTitle from './SectionTitle'
import { useEffect } from "react";
 const StyledStepper = styled(Stepper)({
  "& .MuiStepConnector-line": {
    borderLeftWidth: 2,
    borderStyle: "none",
    borderColor: "#fff",
  },
});

 
 const permisSteps = [
  { label: "Préparer les documents nécessaires", description: "Voir la liste des pièces à fournir" },
  { label: "Se rendre dans un centre de l’ANATS", description: "Trouver un centre." },
  { label: "Soumettre sa demandee"},
  { label: "Attendre sa validation et production" },
  { label: "Suivre et Récupérer son titre au centre"},
];

 
const ImmatriculationStepper = () => {
   useEffect(() => {
        window.scrollTo(0, 0); // Scrolls to the top when the page loads
      }, []);
  return (
    <Grid container spacing={4} sx={{ m: 4, px: 3 }}>
       <Grid item xs={12} md={8}>
        <Paper elevation={6} sx={{ p: 4, borderRadius: 4, backgroundColor: "#f9f9f9" }}>
          <Typography variant="h5" sx={{ textAlign: "center", fontWeight: "bold", mb: 2, color: "#1976d2" }}>
         
          <SectionTitle text="Permis de Conduire ou Immatriculation - Étapes à suivre" color="#E63946" />
          
          </Typography>
          <StyledStepper orientation="vertical" connector={<StepConnector />}>
            {permisSteps.map((step, index) => (
              <Step   sx={{ maxWidth:"60%", textAlign: "left"}} key={index} active>
                <StepLabel
                   
                >
                  <Typography fontWeight="">{step.label}  {step.description}</Typography>
                </StepLabel>
               </Step>
            ))}
          </StyledStepper>
        </Paper>
      </Grid>

        
    </Grid>
  );
};

export default ImmatriculationStepper;